
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"viewBox":"0 0 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"fill":"currentColor","d":"M1.467 3.533l4.594 4.594-1.06 1.06L.405 4.595z"}}),_c('path',{attrs:{"fill":"currentColor","d":"M.406 4.594L5 0l1.061 1.06-4.594 4.595z"}})])
          )
        }
      }
    