import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'

@Module
export class EBNMarket extends VuexModule {
  
  public currentMarketEBN: string = "en";
  public currentLocaleEBN: string = "en";
  public allMarkets = process.env.DEBUG == "true" ? process.env.VUE_APP_MARKET_EBN : process.env.VUE_APP_MARKET_EBN.split(",");

  init() {
    this.market();
    this.locale();
  }

  market() {
    if (process.env.VUE_APP_DEBUG === "true") {
      this.setMarketEBN(process.env.VUE_APP_MARKET_EBN);
      return;
    }
    
    // DEBUG
    for (let i = 0; i < this.allMarkets.length; i++) {
      if(window.location.pathname.includes(`${this.allMarkets[i]}`) || window.location.hostname.includes(`${this.allMarkets[i]}`)) {
        this.setMarketEBN(this.allMarkets[i]);
      }
    }
  }

  locale() {
    if (process.env.VUE_APP_DEBUG === "true") {
      this.setLocaleEBN(process.env.VUE_APP_CURRENT_LANG_EBN);
      return;
    }

    // DEV
    for (let i = 0; i < this.allMarkets.length; i++) {
      if(window.location.pathname.includes(`/${this.allMarkets[i]}/`)) {
        const locale = window.location.pathname.replace(`/${this.allMarkets[i]}/`, '').split(/\s/).join('');
        this.setLocaleEBN(locale)
      }
    }

    // MX
    if(window.location.hostname.includes('mx')) {
      this.setLocaleEBN('es-mx');
      this.setMarketEBN('mx');
    }
  }

  @Action
  setMarketEBN( v: string ) {
    this._SET_MARKET_EBN(v)
  }

  @Mutation
  private _SET_MARKET_EBN( v: string ) {
    this.currentMarketEBN = v
  }

  @Action
  setLocaleEBN( v: string ) {
    this._SET_LOCALE_EBN(v)
  }

  @Mutation
  private _SET_LOCALE_EBN( v: string ) {
    this.currentLocaleEBN = v
  }
}

const ebn_market = new EBNMarket({ store, name: 'ebn_market' });
ebn_market.init();

export default ebn_market;