
import { GLContext } from 'nanogl/types';
import Scene from './Scene';


function now(){
  return performance.now()
}

class GLView {

  pixelRatio: number;
  gl: GLContext;
  canvas: HTMLCanvasElement;
  width: number;
  height: number;
  canvasWidth: number;
  canvasHeight: number;
  previousTime: number;
  _rafId: number;
  _playing: boolean;
  scene: Scene;

  constructor(cvs: HTMLCanvasElement, {
    depth = true,
    alpha = false,
    pixelRatio = -1
  } = {}) {


    if (pixelRatio < 0) {
      this.pixelRatio = Math.min(3.0, window.devicePixelRatio);
    } else {
      this.pixelRatio = pixelRatio;
    }

    const opts: WebGLContextAttributes =
    {
      depth: depth,
      antialias: false,
      stencil: false,
      alpha: alpha,
      premultipliedAlpha: false,
      preserveDrawingBuffer: false,
      powerPreference: "high-performance"

    }

    /**
     * @type {WebGLRenderingContext}
     */
    this.gl = (
      cvs.getContext('webgl2', opts) ||
      cvs.getContext('webgl', opts) ||
      cvs.getContext('experimental-webgl', opts) ||
      cvs.getContext('webgl')) as GLContext;

    this.gl.clearColor(1, 1, 1, 1);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    this.canvas = cvs;

    this.width = 0;
    this.height = 0;

    this.canvasWidth = 0;
    this.canvasHeight = 0;

    this.previousTime = now();
    this._rafId = 0;
    this._playing = false;

    this.scene = null;

  }




  onWindowResize = ()=>{
    this._checkSize()
  }


  updateSize() {
    const pr = this.pixelRatio;

    this.canvas.width = Math.ceil(pr * this.canvasWidth / 4.0) * 4.0;
    this.canvas.height = Math.ceil(pr * this.canvasHeight / 4.0) * 4.0;
    this.width = this.gl.drawingBufferWidth;
    this.height = this.gl.drawingBufferHeight;
    this.resize();
  }



  _checkSize() {

    // const w = window.innerWidth;
    // const h = window.innerHeight;
    const w = this.canvas.clientWidth;
    const h = this.canvas.clientHeight;


    if (isNaN(w) || isNaN(h) || w === 0 || h === 0) {
      return false;
    }
    if (w !== this.canvasWidth || h !== this.canvasHeight) {

      this.canvasWidth = w;
      this.canvasHeight = h;
      this.updateSize();
    }
    return true;
  }

///////////////
//////////
////
//////////////////////////////////////////////////////////
/////////////////////////
/////////////////////////
/////////////////
//////////////////////////////
///


///////////////////
/////////////////////////////////////////////
///////////////////////////////////////////////////////////
///

/////////////////
/////////////////////////
/////////////
/////
///////////////////////
///////////////////////////////////////////////
/////////////////////////////
/////////////////////////////////////
//////////////////
/////

////////////////////
////
////////////////////////
///////////////////////////
/////
///
////////////



  render(dt:number) {
    if (this.scene)
      this.scene.render(dt);
  }


  resize() {

    if (this.scene)
      this.scene.handleResize();
  }


}



export default GLView;