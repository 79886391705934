














import Delay from "@/core/Delay";
import ev_hotspots, { HotspotData } from "@/store/modules/ev_hotspots";
import ev_state, { ViewType } from "@/store/modules/ev_state";
import Vue from "vue";
import Component from "vue-class-component";
import EVHotspot from "./EVHotspot.vue";

@Component({
  components: {
    EVHotspot,
  },
})
export default class EVHotspots extends Vue {
  showHp: Array<boolean> = [];
  numberHp: number = 0;

  get hotspots(): HotspotData[] {
    return ev_hotspots.list;
  }

  beforeMount() {
    for (let i = 0; i < this.hotspots.length; i++) {
      this.showHp.push(false);
    }
  }

  mounted() {
    this.startTrans();

    ev_state.$watch(
      (s) => s.currentUsp,
      (data) => {
        if (data === null) this.$el.classList.remove("hide");
        else this.$el.classList.add("hide");
      }
    );
  }

  async startTrans() {
    for (let i = 0; i < this.hotspots.length; i++) {
      await Delay(50);
      this.showHp[i] = true;
      this.$forceUpdate();
    }
  }
}
