
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"width":"30","height":"30","viewBox":"0 0 50 50","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M25 50C11.215 50 0 38.785 0 25.001 0 11.215 11.215 0 25 0s25 11.215 25 25.001C50 38.785 38.785 50 25 50zm0-47.368c-12.334 0-22.368 10.034-22.368 22.369 0 12.334 10.034 22.367 22.368 22.367 12.333 0 22.368-10.033 22.368-22.367C47.368 12.666 37.333 2.632 25 2.632zm10.403 32.773a1.312 1.312 0 01-1.861 0l-8.543-8.544-8.543 8.544a1.31 1.31 0 01-1.86 0 1.315 1.315 0 010-1.86l8.543-8.544-8.543-8.544a1.316 1.316 0 011.86-1.861l8.543 8.544 8.543-8.544a1.316 1.316 0 111.861 1.861l-8.543 8.544 8.543 8.544a1.315 1.315 0 010 1.86z","fill":"#fff"}})])
          )
        }
      }
    