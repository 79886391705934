import Vue from 'vue'


export type HotspotData = {
  id:string
  x:number
  y:number
  opacity: number
}

export type HotspotsStore = {
  list:HotspotData[]
}


const ev_hotspots:HotspotsStore = Vue.observable({
  list: []
})



export default ev_hotspots
