



















import Vue from "vue";
import Component from "vue-class-component";
import QRCode from "qrcode";
import { LocaleMessage } from "vue-i18n";
import ev_market from "@/store/modules/ev_market";

@Component
export default class FallbackPage extends Vue {
  mounted() {
    this.updateQRCode();
  }

  get title(): LocaleMessage {
    return this.$t("intro.t0") + " " + this.$t("intro.t1");
  }

  get subTitle(): LocaleMessage {
    return this.$t("intro.d0") + " " + this.$t("intro.d1");
  }

  get QRCodeHint1(): LocaleMessage {
    return this.$t("qrcode-hint1");
  }

  get QRCodeHint2(): LocaleMessage {
    return this.$t("qrcode-hint2");
  }

  get modelDisclaimer(): LocaleMessage {
    return this.$t("model_disclaimer");
  }

  get mx() {
    return ev_market.currentMarket == "mx";
  }

  get rtl() {
    return ev_market.currentLocale == "ar-ae";
  }

  updateQRCode() {
    QRCode.toCanvas(this.$refs.qrcode, location.href, {
      width: 120,
      margin: 0,
      color: {
        dark: "#000000",
        light: "#FFFFFF",
      },
    });
  }
}
