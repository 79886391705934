
export const EbnCharacters = [
  'joel'  ,
  'zoe',
  'constance' ,
  'robert' ,
] as const


export type CharacterConfig = {
  id : EbnCharacter
  numUsps: number
  numTriggers: number
  numFailure: number
  numSuccess: number
  numWaitings: number
}

export type EbnCharacter = typeof EbnCharacters[number] 

