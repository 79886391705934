
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"width":"10","height":"10","viewBox":"0 0 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"fill":"currentColor","d":"M8.533 5.655L3.94 1.06 4.999 0l4.595 4.593z"}}),_c('path',{attrs:{"fill":"currentColor","d":"M9.593 4.594L5 9.188l-1.06-1.06 4.594-4.595z"}})])
          )
        }
      }
    