import Program from "nanogl/program";

import Scene from "@/webgl/Scene";

import VS from './flash.vert'
import FS from './flash.frag'
import { LocalConfig } from "nanogl-state";

export default class Flash {

  prg: Program;

  strength = 0
  cfg : LocalConfig;
  constructor(private scene: Scene){
    const gl = scene.gl

    this.prg = this.scene.programs.create( 'flash', VS(), FS() )
    this.cfg = scene.glstate.config()
      .enableBlend()
      .blendFunc( gl.ONE, gl.ONE )

  }


  render(){
    if( this.strength < 0.001 ) return

    this.prg.use()
    this.prg.uStrength( this.strength )
    this.cfg.apply()
    this.scene.quad.attribPointer( this.prg )
    this.scene.quad.render()

  }




}