import Paths from "@/core/Paths";
import { InteriorColor } from "@/store/models/BodyColors";
import ev_hotspots from "@/store/modules/ev_hotspots";
import ev_state from "@/store/modules/ev_state";
import ev_market from "@/store/modules/ev_market";
import EVModule, { EVView } from "@/webgl/EVModule";
import LazyTexture from "@/webgl/gl/LazyTexture";
import { GLContext } from "nanogl/types";
import InteriorProjector from "../hotspots/InteriorProjector";
import PanoRenderer from "./PanoRenderer";


export class InteriorSkin{

  envmap: LazyTexture;
  ready: boolean = false;

  constructor( readonly gl: GLContext, readonly color:InteriorColor ){
    switch(ev_market.currentMarket) {
      case "kr":
      case "meo":
        this.envmap = new LazyTexture( Paths.resolve(`assets/webgl/interior_blue.png`), true)
        break;
      case "mx":
        this.envmap = new LazyTexture( Paths.resolve(`assets/webgl/interior_mx.png`), true)
        break;
      default:
        this.envmap = new LazyTexture( Paths.resolve(`assets/webgl/interior_${this.color}.png`), true)
        break;
    }
  }

  async load(){
    await this.envmap.load()
    this.envmap.allocate(this.gl)
    this.ready = true
  }
  
  dispose(){
    this.envmap.release()
    this.ready = false
  }
}


export default class InteriorScene implements EVView {

  private renderer: PanoRenderer;
  private _loadStarted = false;
  hotspotProjector: InteriorProjector;
  unwatch: Function;

  skin: InteriorSkin = null

  constructor(private module : EVModule ){
    
    this.renderer = new PanoRenderer( module.scene )
    this.hotspotProjector = new InteriorProjector()

  }

  start(): void {
    switch(ev_market.currentMarket) {
      case "meo":
        ev_hotspots.list = [
          { id:'regen', x:0, y:0, opacity:0 },
          { id:'one_pedal', x:0, y:0, opacity:0 },
          { id:'connected', x:0, y:0, opacity:0 },
          { id:'health_screens', x:0, y:0, opacity:0 },
          { id:'onstar', x:0, y:0, opacity:0 }
        ]
        break;
      case "mx": 
        ev_hotspots.list = [
          { id:'regen', x:0, y:0, opacity:0 },
          { id:'one_pedal', x:0, y:0, opacity:0 },
          { id:'connected', x:0, y:0, opacity:0 },
          { id:'health_screens', x:0, y:0, opacity:0 },
          { id:'performance', x:0, y:0, opacity:0 }
        ]
        break;
      case "kr": 
        ev_hotspots.list = [
          { id:'regen', x:0, y:0, opacity:0 },
          { id:'one_pedal', x:0, y:0, opacity:0 },
          { id:'connected', x:0, y:0, opacity:0 },
          { id:'health_screens', x:0, y:0, opacity:0 }
        ]
        break;
      default: 
        ev_hotspots.list = [
          { id:'super_cruise', x:0, y:0, opacity:0 },
          { id:'regen', x:0, y:0, opacity:0 },
          { id:'one_pedal', x:0, y:0, opacity:0 },
          { id:'connected', x:0, y:0, opacity:0 },
          { id:'health_screens', x:0, y:0, opacity:0 }
        ]
        break;
    }

    this.unwatch = ev_state.$watch( s=>s.interiorColor, ()=>this.onColorChange())
    this.onColorChange()

    this.renderer.start(this.module.scene.camera)
  }

  stop(): void {
    this.unwatch?.()
    ev_hotspots.list = []
  }


  onColorChange(){
    const color = ev_state.interiorColor
    if( this.skin && this.skin.color === color ) return
    this.skin?.dispose()
    ev_state.setInteriorLoaded( false )
    this.skin = new InteriorSkin( this.module.scene.gl, color )
    this.skin.load().then( ()=>{
      ev_state.setInteriorLoaded( true )
    })
  }


  rttRender(): void {
   0
  }

  load() : Promise<void>{
    return Promise.resolve()
    // if( this._loadStarted ) return
    // this._loadStarted = true

    // await this.renderer.load()
    // ev_state.setInteriorLoaded( true )
  }

  preRender():void{
    0
  }

  render(){
    if( this.skin && this.skin.ready ){
      const cam = this.module.scene.camera
      this.renderer.render(cam, this.skin.envmap.texture )
      this.hotspotProjector.projectHotspots(ev_hotspots.list, this.renderer.camera )
    }
  }

}