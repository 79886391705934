




import Paths from "@/core/Paths";
import Lottie, { AnimationItem } from "lottie-web";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class LottieAnimation extends Vue {
  @Prop({ type: String, required: true }) src!: string;
  @Prop({ type: String, default: "canvas" }) type!: "canvas" | "svg" | "html";
  @Prop({ type: Boolean, required: false }) loop!: boolean;
  @Prop({ type: Boolean, required: false }) autoPlay!: boolean;
  @Prop({ type: Number, required: false }) loopAt!: number;

  lottieAnim: AnimationItem;

  mounted() {
    let src = this.src;
    if (src[0] === "/") src = src.substring(1, src.length);
    src = Paths.resolve(src);
    this.lottieAnim = Lottie.loadAnimation({
      container: this.$refs.animation as Element,
      renderer: this.type,
      loop: this.loop,
      autoplay: this.autoPlay,
      path: src,
      rendererSettings: {
        preserveAspectRatio: "xMaxYMid slice",
      },
    });

    if (this.loopAt) {
      this.lottieAnim.addEventListener("complete", this.loopAtFn);
    }
  }

  beforeDestroy() {
    this.lottieAnim.removeEventListener("complete", this.loopAtFn);
  }

  loopAtFn() {
    this.lottieAnim.goToAndPlay(this.loopAt, true);
  }

  play() {
    this.lottieAnim.play();
  }

  stop() {
    this.lottieAnim.goToAndStop(1, true);
  }
}
