import Texture from "nanogl/texture";
import { GLContext } from "nanogl/types";


import { webpSupport } from "@webgl/lib/nanogl-gltf/lib/extensions/EXT_texture_webp/webpSupport";
import TextureCodecStd from "@webgl/assets/TextureCodecStd";



export default class LazyTexture{
  
  texture: Texture;
  buffer: ArrayBuffer
  image: HTMLImageElement | ImageBitmap;

  constructor( private url: string, private alpha: boolean = false, private enableWebp = true){

  }
  
  async load(): Promise<void>{
    const hasWebp = await webpSupport()
    let url = this.url
    if( this.enableWebp && hasWebp ) {
      url = this.url.substring( 0, this.url.length-3 )+'webp'
    }

    const buffer = await (await fetch(url)).arrayBuffer()
    this.image = await TextureCodecStd.decodeImage(buffer)
  }


  async loadAndAllocate(gl: GLContext): Promise<void>{
    await this.load()
    this.allocate(gl)
  }
  
  allocate( gl: GLContext ){
    if( this.texture ) throw 'already allocated'
    this.texture = new Texture( gl, this.alpha? gl.RGBA: gl.RGB )
    this.texture.fromImage( this.image )
  }

  release(){
    this.texture.dispose()
    this.texture = null
  }

}