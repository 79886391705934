import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'

@Module
export class Rtl extends VuexModule {
  
  public value = false

  @Action
  setValue( v:boolean ) {
    this._SET(v)
  }

  @Mutation
  private _SET(v:boolean) {
    this.value = v
  }
}

const rtl = new Rtl({ store, name: 'rtl' })

export default rtl
