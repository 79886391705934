import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'
import { EbnCharacter, EbnCharacters } from '../models/EbnCharacters'
import { ArStateValue } from '../xstate/ebn_arstate'
import { createEbnCharacterStates, EbnCharacterState } from '../datas/ebn_character_configs'
import i18n_ebn from '@/locales/ebn'
import { EBNDisclaimerData } from '../models/EBNDisclaimerData'
import audio_state from './audio_state'



export enum EBNMainState {
  INTRO,
  CAROUSEL,
  AR
}


@Module
export class EbnState extends VuexModule {
  
  // public characterStates : Record<EbnCharacter, EbnCharacterState> = createEbnCharacterStates()



    
  // ====================================================== currentCharacter
  
  public mainState: EBNMainState = EBNMainState.CAROUSEL
  // public mainState: EBNMainState = EBNMainState.AR
  
  @Action
  setMainState( v:EBNMainState ) {
    this._SET_MAIN_STATE(v)
  }
  
  @Mutation
  private _SET_MAIN_STATE(v:EBNMainState) {
    this.mainState = v
  }
  

  
  // ====================================================== currentCharacter
  
  public currentCharacter: EbnCharacter = EbnCharacters[2]
  
  @Action
  setCurrentCharacter( v:EbnCharacter ) {
    this._SET_CURRENT_CHAR(v)
  }
  
  @Mutation
  private _SET_CURRENT_CHAR(v:EbnCharacter) {
    this.currentCharacter = v
  }
  

    
  // ====================================================== currentCharacter
  
  public characterState: ArStateValue = 'inactive'
  
  @Action
  setCharacterState( v:ArStateValue ) {
    this._SET_CHAR_STATE(v)
  }
  
  @Mutation
  private _SET_CHAR_STATE(v:ArStateValue) {
    this.characterState = v
  }
  
  public completedUsps: boolean[] = []

    
  @Action
  setCharacterCompletedUsps(v:boolean[]) {
    this._SET_COMPLETED_USPS(v)
  }
  
  @Mutation
  private _SET_COMPLETED_USPS(v:boolean[]) {
    this.completedUsps.length = 0
    this.completedUsps.push(...v)
  }

  get completedUspsCount():number {
    return this.completedUsps.filter(v=>v).length
  }

  get totalUsps():number {
    return this.completedUsps.length
  }


  public currentAnim: string = "";

  @Action
  setCurrentAnim( v:string ) {
    this._SET_CURRENT_ANIM(v)
  }
  @Mutation
  private _SET_CURRENT_ANIM(v:string) {
    this.currentAnim = v
  }


  public activeUsp = -1

  @Action
  setActiveUsp( v:number ) {
    this._SET_ACTIVE_USP(v)
  }
  @Mutation
  private _SET_ACTIVE_USP(v:number) {
    this.activeUsp = v
  }


  // ========================== currently player animation's currentTime


  public animationCurrentTime = 0

  @Action
  setAnimationCurrentTime( v:number ) {
    this._SET_ANIM_TIME(v)
  }
  @Mutation
  private _SET_ANIM_TIME(v:number) {
    this.animationCurrentTime = v
  }


  

  // ====================================================== DISCLAIMERS

  /**
   * disclaimer panel open during a speech
   */
  public disclaimerOpened: boolean = false

  @Action
  openDisclaimer() {
    this._SET_DISCLAIMER_OPEN(true)
  }

  @Action
  closeDisclaimer() {
    this._SET_DISCLAIMER_OPEN(false)
  }
  
  @Mutation
  private _SET_DISCLAIMER_OPEN(v:boolean) {
    this.disclaimerOpened = v
  }
  
  get currentDisclaimerData() : EBNDisclaimerData | null {
    const tkey = `${this.currentCharacter}.disclaimer_${this.activeUsp}`
    if( i18n_ebn.te(tkey + '.cta')){
      return {
        cta    : i18n_ebn.t(tkey + '.cta').toString(),
        title  : i18n_ebn.t(tkey + '.title').toString(),
        text   : i18n_ebn.t(tkey + '.text').toString(),
        legals : i18n_ebn.t(tkey + '.legals').toString(),
      }
    }
    return null
  }


  // ====================================================== charSelectorOpened
  
  public charSelectorOpened: boolean = false
  
  @Action
  openCharSelector() {
    this.collapseMenu()
    this._SET_CHAR_SEL_OPENED(true)
  }
  
  @Action
  closeCharSelector() {
    this._SET_CHAR_SEL_OPENED(false)
  }
  
  @Mutation
  private _SET_CHAR_SEL_OPENED(v:boolean) {
    this.charSelectorOpened = v
  }
  
  
  // ======================================================
  
  public introDone: boolean = false
  
  @Action
  setIntroDone( v:boolean ) {
    this._SET_INTRO_DONE(v)
  }
  
  @Mutation
  private _SET_INTRO_DONE(v:boolean) {
    this.introDone = v
  }

  // ======================================================
  
  public videoDone: boolean = false
  
  @Action
  setVideoDone( v:boolean ) {
    this._SET_VIDEO_DONE(v)
  }
  
  @Mutation
  private _SET_VIDEO_DONE(v:boolean) {
    this.videoDone = v
  }
  
  
  // ====================================================== menuOpened
  
  public menuExpanded: boolean = false
  
  @Action
  expandMenu() {
    this.closeCharSelector()
    this._SET_MENU_EXPANDED(true)
  }
  
  @Action
  collapseMenu() {
    this._SET_MENU_EXPANDED(false)
  }
  
  @Mutation
  private _SET_MENU_EXPANDED(v:boolean) {
    this.menuExpanded = v
  }
  
  
  // ====================================================== menuOpened
  
  public carouselChar: string = ''
  
  @Action
  setCarouselChar(c:string) {
    this._SET_CAROUSEL_CHAR(c)
  }
  
  @Mutation
  private _SET_CAROUSEL_CHAR(c:string) {
    this.carouselChar = c
  }
  

    
  // ====================================================== subtitlesEnabled
  
  public subtitlesEnabled: boolean = true
  
  @Action
  setSubtitlesEnabled(v:boolean) {
    this._SET_SUBTITLE_ENABLED(v)
  }
  
  @Mutation
  private _SET_SUBTITLE_ENABLED(v:boolean) {
    this.subtitlesEnabled = v
  }
  
  // ====================================================== subtitlesEnabled
  
  public currentSubtitles: string = null
  
  @Action
  setCurrentSubtitles(v:string) {
    if( this.currentSubtitles !== v )
      this._SET_SUBTITLE_TEXT(v)
  }
  
  @Mutation
  private _SET_SUBTITLE_TEXT(v:string) {
    this.currentSubtitles = v
  }
  
    
  // ====================================================== sharePanelOpened
  
  public sharePanelOpened: boolean = false
  
  @Action
  openSharePanel() {
    this._SET_SHARE_PANEL_OPENED(true)
  }
  
  @Action
  closeSharePanel() {
    this._SET_SHARE_PANEL_OPENED(false)
  }
  
  @Mutation
  private _SET_SHARE_PANEL_OPENED(v:boolean) {
    this.sharePanelOpened = v
  }

  // ====================================================== carFeatures
  
  public carFeaturesOpen: boolean = false;
  public currentNameFeature: string = "";
  public currentFeature: any = {};
  
  @Action
  openCarFeatures() {
    this._SET_CAR_FEATURES_OPENED(true)
  }
  
  @Action
  closeCarFeatures() {
    this._SET_CAR_FEATURES_OPENED(false)
  }
  
  @Mutation
  private _SET_CAR_FEATURES_OPENED(v:boolean) {
    this.carFeaturesOpen = v
  }
  
  @Action
  setNameFeature( cnF: string ) {
    this._SET_NAME_FEATURE(cnF)
  }
  
  @Mutation
  private _SET_NAME_FEATURE(cnF: string) {
    this.currentNameFeature = cnF
  }
  
  @Action
  setCurrentFeature( cF: any ) {
    this._SET_CURRENT_FEATURE(cF)
  }
  
  @Mutation
  private _SET_CURRENT_FEATURE(cF: any) {
    this.currentFeature = cF
  }
    
  // ====================================================== sharePanelOpened
  
  public menuPanelOpened: boolean = false
  
  @Action
  openMenuPanel() {
    this._SET_MENU_PANEL_OPENED(true)
  }
  
  @Action
  closeMenuPanel() {
    this._SET_MENU_PANEL_OPENED(false)
  }
  
  @Mutation
  private _SET_MENU_PANEL_OPENED(v:boolean) {
    this.menuPanelOpened = v
  }
    
  // ====================================================== sharePanelOpened
  
  public forceMultipleAnimation: number = -1
  public currentZoe: number = -1
  
  @Action
  setForceMultipleAnimation(n:number) {
    this.SET_FORCE_MULTIPLE_ANIMATION(n)
  }
  
  @Mutation
  private SET_FORCE_MULTIPLE_ANIMATION(n:number) {
    this.forceMultipleAnimation = n
    if(n !== -1) this.currentZoe = n
  }
  
  // ======================================================
  
  public isFPSWarning: boolean = false
  
  @Action
  setIsFPSWarning( v:boolean ) {
    this._SET_IS_FPS_WARNING(v)
  }
  
  @Mutation
  private _SET_IS_FPS_WARNING(v:boolean) {
    this.isFPSWarning = v
  }

  // ====================================================== Cookies

  public showCookies: boolean = true
  
  @Action
  showBannerCookies(t:boolean) {
    this._SET_SHOW_COOKIES(t)
  }

  @Mutation
  private _SET_SHOW_COOKIES(t:boolean) {
    this.showCookies = t
  }

  // ====================================================== Switch characters

  public switchCharacters: boolean = false
  
  @Action
  setSwitchCharacters(t:boolean) {
    this._SET_SWITCH_CHARACTERS(t)
  }
  
  @Mutation
  private _SET_SWITCH_CHARACTERS(t:boolean) {
    this.switchCharacters = t
  }

    
  // ====================================================== Volume Meter

  public volumeMeterEnabled: boolean = false
  
  @Action
  toggleVolumMeter(t:boolean) {
    this._SET_VOLUME_METER_ENABLED(t)
  }
  
  @Mutation
  private _SET_VOLUME_METER_ENABLED(t:boolean) {
    this.volumeMeterEnabled = t
  }

  
    
  // ====================================================== robert's interaction

  public riTouchID: number = -1
  
  get riDragged(): boolean {
    return this.riTouchID !== -1
  }
  
  @Action
  ri_startDrag( touchid:number) {
    this._SET_RI_TOUCHID(touchid)
  }
  
  @Action
  ri_stopDrag() {
    this._SET_RI_TOUCHID(-1)
  }
  
  @Mutation
  private _SET_RI_TOUCHID(v:number) {
    this.riTouchID = v
  }

  // ====================================================== animation back to carousel

  public backToCarousel: boolean = false;
  
  @Action
  setBackToCarousel(n: boolean) {
    this._SET_BACK_TO_CAROUSEL(n)
  }
  
  @Mutation
  private _SET_BACK_TO_CAROUSEL(n: boolean) {
    this.backToCarousel = n
  }
    
  // ====================================================== set the carousel as revisiting

  public isRevisit:number = -1  
  
  @Action
  setIsRevisit(n:number) {
    this._SET_IS_REVISIT(n)
  }
  
  @Mutation
  private _SET_IS_REVISIT(n:number) {
    this.isRevisit = n
  }

}

const ebn_state = new EbnState({ store, name: 'ebn_state' })

export default ebn_state
