
import Program from 'nanogl/program'
import VS from './shader.vert'
import FS from './shader.frag'
import { mat4, quat } from 'gl-matrix'
import GLArrayBuffer from 'nanogl/arraybuffer'
import LazyTexture from '@/webgl/gl/LazyTexture'
import PerspectiveLens from 'nanogl-camera/perspective-lens'
import Camera from 'nanogl-camera'
import { LocalConfig } from 'nanogl-state'
import Scene from '@/webgl/Scene'
import Texture2D from 'nanogl/texture-2d'
import gui         from '@webgl/dev/Gui'
import Node from 'nanogl-node'




const INV_PROJ = mat4.create()


// FOR SCREEN SCREEN

const QUAD_VERTICES = new Float32Array([
  -1, -1, 
  -1, 1, 
  1, -1, 
  1, 1
])


export default class PanoRenderer {
  /**
   * the latlon projection shader
   */
  prg: Program

  /**
   * fullscreen quad
   */
  quad: GLArrayBuffer

  /**
   * latlon texture
   */
  envmap: LazyTexture


  cfg: LocalConfig
  camera: Camera<PerspectiveLens>

  root:Node


  constructor(private scene: Scene) {



    this.camera = Camera.makePerspectiveCamera()
    this.camera.lens.setAutoFov(1.5)

    this.root = new Node()
    this.root.add(this.camera)
    
    
    this.prg = new Program(scene.gl, VS(), FS())
    this.quad = new GLArrayBuffer(scene.gl, QUAD_VERTICES).attrib('aPosition', 2, scene.gl.FLOAT)

    this.cfg = scene.glstate.config()
      .enableDepthTest(false)
      .enableBlend()
      .blendFunc(scene.gl.SRC_ALPHA, scene.gl.ONE_MINUS_SRC_ALPHA)
  }

  start(camera : Camera<PerspectiveLens>) {
    quat.invert(this.root.rotation, camera.rotation);
    this.root.rotation[0] = 0
    this.root.rotation[2] = 0
    this.root.invalidate()
  }

  render( camera : Camera<PerspectiveLens>, envmap: Texture2D ) {
    // quat.mul(this.addCamRot, camera.rotation, this.addCam)
    // quat.rotateY(this.addCamRot, camera.rotation, this.addCamRotX)
    this.camera.rotation.set(camera.rotation)
    this.camera.invalidate()
    this.camera.updateWorldMatrix()
    this.camera.updateViewProjectionMatrix(this.scene.glview.width, this.scene.glview.height)
    this.camera.lens._proj.set( camera.lens._proj )

    this.camera.lens._valid = true;

    const VP =  this.camera._viewProj
    

    mat4.invert(INV_PROJ, VP)

    // bind program
    this.prg.use()

    // vertexAttribPointer
    this.quad.attribPointer(this.prg)
    // vertex for screen

    // setup program's uniforms
    this.prg.tLatLon(envmap)
    this.prg.uUnproject(INV_PROJ)

    
    this.cfg.apply()
    // drawArray()
    this.quad.drawTriangleStrip()


  }

  destroy() {
    // this.gui.destroy()
  }
}
