
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"width":"23","height":"28","viewBox":"0 0 23 28","fill":"none","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M22.877 13.462a2.553 2.553 0 00-2.542-2.542c-.527 0-1.054.155-1.457.465-.372-.93-1.302-1.612-2.356-1.612-.527 0-1.054.155-1.457.465-.372-.93-1.302-1.612-2.356-1.612-.466 0-.899.124-1.272.342l.001-5.861c0-.682-.278-1.333-.743-1.798C10.198.844 9.578.565 8.896.565a2.553 2.553 0 00-2.541 2.542L6.353 16.22l-1.766-1.766a2.58 2.58 0 00-3.255-.31A2.715 2.715 0 00.215 15.91c-.093.714.093 1.457.558 2.015l5.239 6.324c1.581 1.891 3.875 3.006 6.355 3.006h3.255a7.242 7.242 0 005.177-2.138 7.318 7.318 0 002.138-5.177l-.06-6.478zm-3.038 10.725a6.004 6.004 0 01-4.279 1.767h-3.254a7.007 7.007 0 01-5.393-2.542l-5.21-6.323a1.215 1.215 0 01-.278-1.024 1.33 1.33 0 01.558-.898c.217-.155.465-.217.744-.217.341 0 .681.124.93.372l2.108 2.077c.31.31.775.403 1.178.216.403-.155.652-.558.652-.991L7.593 3.107c0-.713.559-1.272 1.272-1.272.341 0 .651.124.898.372.249.248.372.558.372.899v9.331a.621.621 0 001.24 0v-1.271c0-.713.559-1.272 1.272-1.272s1.271.559 1.271 1.272v2.418a.621.621 0 001.24 0v-1.271c0-.714.559-1.272 1.272-1.272s1.271.558 1.271 1.272v2.418a.621.621 0 001.24 0v-1.272c0-.713.559-1.271 1.272-1.271s1.271.558 1.271 1.271v6.479c.122 1.584-.498 3.102-1.646 4.249z","fill":"#fff"}})])
          )
        }
      }
    