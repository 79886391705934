var fn = function( obj ){
var __t,__p='';
__p+=''+
( require('./octwrap-decode.glsl')() )+
'\n'+
( require('./decode-rgbe.glsl')() )+
'\n\nvec3 SampleEnvHi( sampler2D tEnv, vec3 skyDir ) {\n  vec2 uvA = octwrapDecode( skyDir );\n\n  uvA = vec2(\n    0.25*(254.0/256.0),\n    0.5 *(254.0/256.0)\n    ) * uvA + vec2(0.5,0.5);\n\n  return decodeRGBE( texture2D(tEnv, uvA) );\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;