var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"cta ui-btn",class:[
    {
      primary: _vm.primary,
      outlined: _vm.outlined,
      whiteOutlined: _vm.whiteOutlined,
      rounded: _vm.rounded,
      compact: _vm.compact,
      large: _vm.large,
      _isdisabled: _vm._isdisabled,
      rtl: _vm.rtl,
      'elevation-12': _vm.shadow,
    },
    _vm.schemeClass ],attrs:{"type":"button"},on:{"click":_vm._onClick}},[_vm._t("default"),(_vm.loading)?_c('span',{staticClass:"loading-overlay"},[_c('UISpinner')],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }