import ev_state, { ViewType } from "@/store/modules/ev_state";
import ExteriorScene from "./entities/ExteriorScene";
import InteriorScene from "./entities/InteriorScene";
import IBLManager from "./gl/IBLManager";
import IModule from "./IModule";
import Scene from "./Scene";
import Time, { FpsSampler } from "./Time";


const FPS_THRESHOLD = 14


export interface EVView {
  start():void
  stop():void

  load():Promise<void>
  preRender():void
  rttRender():void
  render():void
}

export default class EVModule implements IModule {
  
  scene: Scene
  exterior: ExteriorScene
  interior: InteriorScene;
  iblMngr   : IBLManager

  currentView : EVView = null
  fpsSampler: FpsSampler

  getViewForType( t:ViewType ): EVView {
    if( t === ViewType.INSIDE ) return this.interior 
    else return this.exterior
  }
  
  init( scene: Scene): void {
    this.scene = scene
    this.scene.envRotation = 6.2
    this.iblMngr     = new IBLManager( scene )
    this.exterior    = new ExteriorScene( this )
    this.interior    = new InteriorScene( this )
    this.fpsSampler  = new FpsSampler(150)
  }

  setCurrentView( v:EVView ){
    
    if( v === this.currentView ) return;

    if( this.currentView ){
      this.currentView.stop()
    }
    
    this.currentView = v
    this.currentView.load()
    this.currentView.start()
  }

  
  get ready(){
    if( ev_state.viewType === ViewType.INSIDE) {
      return ev_state.interiorLoaded
    } else {
      return ev_state.exteriorLoaded
    }
  }
  
  preRender(): void {
    
    this.fpsSampler.sampleFrame()
    const fps = this.fpsSampler.fps
    
    
    if ( fps <= FPS_THRESHOLD && !ev_state.isFPSWarning) {
      ev_state.setIsFPSWarning(true)
    }


    this.setCurrentView( this.getViewForType(ev_state.viewType) )

    if( this.ready )
      this.currentView?.preRender()
  }
  
  rttRender(): void {
    if( this.ready )
      this.currentView?.rttRender()
  }
  
  render(): void {
    if( this.ready )
      this.currentView?.render()
  }

  load(): Promise<void> {
    return this.exterior.load()
  }

}