import { vec3, mat4 } from "gl-matrix"
import Camera from 'nanogl-camera'


const V1   = vec3.create()
const IMVP = mat4.create()



export default class Ray {

  pos : vec3
  dir : vec3
  
  _invalid = true

  constructor(){
    this.pos = vec3.create()
    this.dir = vec3.create()
  }
  
  
  unproject( coords:ArrayLike<number>, cam : Camera ){
    mat4.invert( IMVP, cam._viewProj );
    
    V1[0] = coords[0];
    V1[1] = coords[1];
    
    V1[2] = -1
    vec3.transformMat4( this.pos, V1, IMVP );
    
    V1[2] = 1
    vec3.transformMat4( this.dir, V1, IMVP );
    
    vec3.subtract( this.dir, this.dir, this.pos );
    vec3.normalize( this.dir, this.dir );
  }
  
  
  invalidate(){
    this._invalid = true;
  }
      
  
}

