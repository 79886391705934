import Vue from 'vue'
import VueI18n, { LocaleMessages } from 'vue-i18n'
import ebn_market from '@/store/modules/ebn_market';

const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i)

Vue.use(VueI18n)

function loadLocaleMessages (): LocaleMessages {
  const messages: LocaleMessages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  
  return messages
}

const i18n_ebn = new VueI18n({
  locale: ebn_market.currentLocaleEBN || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

/* const descEl = document.querySelector('head meta[name="description"]');
const titleEl = document.querySelector('head title');
if(titleEl && i18n_ebn && (i18n_ebn.messages[i18n_ebn.locale] as any).share) titleEl.textContent = (i18n_ebn.messages[i18n_ebn.locale] as any).share.title;
if(descEl && i18n_ebn && (i18n_ebn.messages[i18n_ebn.locale] as any).share) descEl.setAttribute('content', (i18n_ebn.messages[i18n_ebn.locale] as any).share.description); */

export default i18n_ebn