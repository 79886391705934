/* eslint-disable @typescript-eslint/no-explicit-any */

import rtl from '@/store/modules/rtl'
import Vue     from 'vue'

Vue.directive('autodir', {
  // When the bound element is inserted into the DOM...
  bind(el) {
    function onChange(newval:boolean){
      el.setAttribute( 'dir', newval?'rtl':'auto')
    }
    (el as any)["__autodirdispose"] = rtl.$watch( (m)=>m.value, onChange )
    onChange( rtl.value )
  },
  
  unbind(el){
    (el as any)["__autodirdispose"]()

  }


})