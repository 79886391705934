











/////////////
////////////////////////////////////////////////
//////////

import Vue from "vue";
import Component from "vue-class-component";

import RotateHint from "@/components/RotateHint/RotateHint.vue";
import EVArPage from "@/components/EVArPage/EVArPage.vue";
import EVDesktopPage from "@/components/EVDesktopPage/EVDesktopPage.vue";
import EVCookiesBanner from "@/components/EVCookiesBanner/EVCookiesBanner.vue";
import ev_state from '../store/modules/ev_state';



@Component({
  components: {
    EVArPage,
    RotateHint,
    EVCookiesBanner,
    EVDesktopPage,
  },
})
export default class EvEntry extends Vue {

  get fpsComponent(){
/////////////////
////////////////
//////////////
  }

  get isMobile() {
/////////////////
////////////////////////////////////////////////////////
//////////////////
/////
//////////////

    const isDesktopMQ = window.matchMedia("only screen and (pointer: coarse)");
    //console.log("sDesktopMQ.matches", isDesktopMQ.matches);
    return isDesktopMQ.matches;
  }
  
}
