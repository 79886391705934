










































import Delay from "@/core/Delay";
import {
  BodyColor,
  BodyColorConfigs,
  BodyColors,
  InteriorColor,
} from "@/store/models/BodyColors";
import ev_state, { ViewType } from "@/store/modules/ev_state";
import Vue from "vue";
import Component from "vue-class-component";
import EVColorPickerItem from "./EVColorPickerItem.vue";
import ChevronLeft from "!vue-svg-loader!@/assets/icons/chevron-left.svg";
import Tracking from "@/core/Tracking";
import ev_market from "@/store/modules/ev_market";
import { LocaleMessage } from "vue-i18n";

export type Color = {
  id: string;
  hex: string;
};

@Component({
  components: {
    EVColorPickerItem,
    ChevronLeft,
  },
})
export default class EVColorPicker extends Vue {
  showPicker = false;
  showInit = false;
  currColor = "#d2d2d2";
  showDisclaimer = false;

  get colorDisclaimerLocale(): LocaleMessage {
    return this.$t("hints.color_disclaimer");
  }

  get colors(): Color[] {

    if(ev_market.currentMarket == "kr") {
      return [
        {
          id: "white",
          hex: "#d2d2d2",
        },
        {
          id: "red",
          hex: "#D73344",
        }
      ];
    }

    if (ev_state.viewType === ViewType.OUTSIDE) {
      return BodyColors.map((c) => ({
        id: c,
        hex: BodyColorConfigs[c].picto,
      }));
    } else {
      return [
        {
          id: "white",
          hex: "#d2d2d2",
        },
        {
          id: "dark",
          hex: "#334565",
        },
      ];
    }
  }

  get wheelColors(): Color[] {

    if(ev_market.currentMarket == "kr") {
      return [
        {
          id: "white",
          hex: "#d2d2d2",
        },
        {
          id: "red",
          hex: "#D73344",
        }
      ];
    }

    if (ev_state.viewType === ViewType.OUTSIDE) {
      return this.colors;
    } else {
      return [
        {
          id: "white",
          hex: "#d2d2d2",
        },
        {
          id: "white1",
          hex: "#d2d2d2",
        },
        {
          id: "dark",
          hex: "#334565",
        },
        {
          id: "dark1",
          hex: "#334565",
        },
      ];
    }
  }

  get twoColors(): boolean {
    return ev_market.currentMarket == "kr"
  }

  get hidePicker(): boolean {
    return (ev_market.currentMarket == "kr" || ev_market.currentMarket == "meo" || ev_market.currentMarket == "mx") && ev_state.viewType === ViewType.INSIDE
  }

  toggleColorPicker() {
    this.showPicker = !this.showPicker;

    const isInside = ev_state.viewType === ViewType.INSIDE;
    if (this.showPicker) {
      Tracking.openColors(isInside);
    } else {
      Tracking.closeColors(isInside);
    }
  }

  async chooseColor(color: string, isTracking: boolean = true) {
    // if (ev_state.viewType === ViewType.INSIDE) {
    //   ev_state.setIsLoading(true);
    //   await Delay(1000);
    // }
    const colorObj = this.colors.filter((c) => c.id === color)[0];
    this.currColor = colorObj.hex;
    if (ev_state.viewType === ViewType.OUTSIDE) {
      ev_state.setBodyColor(color as BodyColor);
      if ((colorObj.id === "blue" && ev_market.currentMarket !== "mx") || (colorObj.id === "red" && ev_market.currentMarket !== "kr")) {
        this.colorDisclaimer();
      } else {
        this.showDisclaimer = false;
      }
    } else ev_state.setInteriorColor(color as InteriorColor);
    this.showPicker = false;

    if (isTracking)
      Tracking.selectColor(ev_state.viewType === ViewType.INSIDE, color);

    // if (ev_state.viewType === ViewType.INSIDE) ev_state.setIsLoading(false);
  }

  mounted() {
    this.chooseColor(this.colors[0].id, false);
    this.startTrans();
    ev_state.$watch(
      (s) => s.viewType,
      () => {
        this.chooseColor(this.colors[0].id, false);
        this.showPicker = false;
      }
    );
    ev_state.$watch(
      (s) => s.currentUsp,
      (data) => {
        if (data === null) this.$el.classList.remove("hide");
        else this.$el.classList.add("hide");
      }
    );
  }

  async startTrans() {
    this.showInit = false;
    await Delay(200);
    this.showInit = true;
  }

  async colorDisclaimer() {
    this.showDisclaimer = true;
    await Delay(4000);
    if(this.showDisclaimer) this.showDisclaimer = false;
  }
}
