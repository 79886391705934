var fn = function( obj ){
var __t,__p='';
__p+='#pragma SLOT version\n\n#pragma SLOT definitions\n\n\n#ifndef hasNormals\n  #define hasNormals 1\n#endif \n#ifndef hasTangents\n  #define hasTangents hasNormals\n#endif \n\n#if hasTangents && !hasNormals \n  #pragma error tan but no nrm\n  error\n#endif\n\n#pragma SLOT precision\n\n'+
( require( "nanogl-pbr/glsl/includes/glsl-compat.vert" )() )+
'\n\n#pragma SLOT pv\n\n\nIN vec3 aPosition;\n\n#if hasNormals\nIN vec3 aNormal;\nOUT mediump vec3 vWorldNormal;\n#endif\n\n// has normal map and tangent attribute\n#if hasTangents\nIN vec4 aTangent;\n#endif\n\n#if HAS_normal && hasTangents\nOUT mediump vec3 vWorldTangent;\nOUT mediump vec3 vWorldBitangent;\n#endif\n\n\n// uniform mat4 uMVP;\nuniform mat4 uWorldMatrix;\nuniform mat4 uVP;\n\nOUT vec3 vWorldPosition;\n\n\n\n\n\n#if perVertexIrrad\n  OUT vec3 vIrradiance;\n  uniform vec4 uSHCoeffs[7];\n  '+
( require( "nanogl-pbr/glsl/includes/spherical-harmonics.glsl" )() )+
'\n#endif\n\n\n\nvec3 rotate( mat4 m, vec3 v )\n{\n  return m[0].xyz*v.x + m[1].xyz*v.y + m[2].xyz*v.z;\n}\n\n\nstruct VertexData {\n  highp vec3 position;\n  highp vec3 worldPos;\n  #if hasNormals\n    vec3 normal;\n  #endif\n  #if hasTangents\n    vec3 tangent;\n  #endif\n  mat4 worldMatrix;\n};\n\n\nvoid InitVertexData( out VertexData vertex ){\n\n  vertex.position = aPosition;\n  #if hasNormals\n    vertex.normal = aNormal;\n  #endif\n  #if hasTangents\n    vertex.tangent = aTangent.xyz;\n  #endif\n\n  vertex.worldMatrix = uWorldMatrix;\n   \n}\n\n\nvoid main( void ){\n\n  #pragma SLOT v\n\n  VertexData vertex;\n  InitVertexData( vertex );\n\n  #pragma SLOT vertex_warp\n\n  vec4 worldPos = vertex.worldMatrix * vec4( vertex.position, 1.0 );\n  vertex.worldPos.xyz = worldPos.xyz / worldPos.w;\n\n  #pragma SLOT vertex_warp_world\n\n  gl_Position     = uVP * vec4( vertex.worldPos, 1.0 );\n  vWorldPosition  = vertex.worldPos;\n  \n  #if hasNormals\n  vWorldNormal    = normalize( rotate( vertex.worldMatrix, vertex.normal ) );\n  #endif\n\n  #if HAS_normal && hasTangents\n    vWorldTangent   = normalize( rotate( vertex.worldMatrix, vertex.tangent.xyz ) );\n    vWorldBitangent = normalize( cross( vWorldNormal, vWorldTangent ) * aTangent.w );\n  #endif\n\n  #if perVertexIrrad\n    vIrradiance = SampleSH( vWorldNormal, uSHCoeffs );\n  #endif\n\n  // vDebugColor = vec4( -position, 1.0 );\n}';
return __p;
};
fn.toString=fn;
module.exports = fn;