import audio_state from "@/store/modules/audio_state";
import { Howl, Howler } from "howler";



audio_state.$watch(
  (m)=>m.mainVolume,
  (mainVolume:number)=>{
    if(!Howler.masterGain) return
    const gain = Howler.masterGain.gain
    gain.setValueAtTime( gain.value, Howler.ctx.currentTime)
    gain.linearRampToValueAtTime(mainVolume, Howler.ctx.currentTime+.3)
  }
)


export const HowlerUtils = {

  loadHowl( sound:Howl ) : Promise<void> {
    return new Promise( (resolve)=>{
      sound.once('load', resolve);
      sound.load()
    })

  }
}
