
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"width":"10","height":"10","viewBox":"0 0 10 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('g',{attrs:{"opacity":".8","fill":"#fff"}},[_c('path',{attrs:{"d":"M.718 3.892h8.726v2.38H.718z"}}),_c('path',{attrs:{"d":"M6.27.719v8.726H3.89V.719z"}})])])
          )
        }
      }
    