import { HowlerUtils } from "@/core/Audio";
import Paths from "@/core/Paths";
import ev_hotspots from "@/store/modules/ev_hotspots";
import ev_state, { ARInitState } from "@/store/modules/ev_state";
import ARController from "@/webgl/ar/ARController";
import EVModule, { EVView } from "@/webgl/EVModule";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import { easeInQuad } from "@/webgl/math/ease";
import { quat, vec3 } from "gl-matrix";
import { Howl, Howler } from "howler";
import Node from "nanogl-node";
import ExteriorProjector from "../hotspots/ExteriorProjector";
import CarScene from "./CarScene";
import PlugScene from "./PlugScene";


export default class ExteriorScene implements EVView {
  
  carscene: CarScene
  plugscene: PlugScene;

  arControler : ARController
  ctrlNode: Node
  hotspotProjector: ExteriorProjector;


  audioIn   : Howl
  audioHold : Howl
  audioOut  : Howl

  private _loadStarted = false;
  lowpassFilter: BiquadFilterNode;
  lpGain: GainNode;

  constructor( private module:  EVModule ){

    this.ctrlNode = new Node()

    this.arControler = new ARController(module.scene, this.ctrlNode )
    this.arControler.start()
    

    this.carscene = new CarScene( module )
    this.plugscene = new PlugScene( module )

    module.scene.root.add(this.ctrlNode)
    
    
    ev_state.$watch( s=>s.arInitState, (newval)=>this.onStateChange(newval))
    this.onStateChange(ev_state.arInitState)


    this.audioIn    = new Howl({ src:Paths.resolve( 'assets/audio/EV-EFFECT_CONNECTOR-POP.mp3' ), preload:false })
    this.audioHold  = new Howl({ src:Paths.resolve( 'assets/audio/EV-EFFECT_HOLD_LOOP.mp3' ), preload:false, loop:true, sprite:{ main:[25, 4748, true] } })
    this.audioOut   = new Howl({ src:Paths.resolve( 'assets/audio/EV-EFFECT_CONNECT.mp3' ), preload:false })
  }


  onStateChange(newval: ARInitState): void {
    switch (newval) {
      
      case ARInitState.PLUG_INTRO:
        this.plugscene.playPlugAnim()
        this.audioIn.play()
        break;
      
      case ARInitState.CHARGING:
        this.audioHold.play('main')
        this.lpGain.gain.setValueAtTime( 0, Howler.ctx.currentTime )
        this.lpGain.gain.linearRampToValueAtTime( 1, Howler.ctx.currentTime + .4 )
        break;
        
      case ARInitState.REVEAL:
        this.carscene.playIntro()
        this.audioOut.play()
        this.audioHold.stop()
        break;
        
      default:
        break;
    }
    
  }

  start(): void {
    ev_hotspots.list = [
      {id:'front_camera', x:0, y:0, opacity:0 },
      {id:'front_grille', x:0, y:0, opacity:0 },
      {id:'charge'      , x:0, y:0, opacity:0 },
      {id:'rear_lights' , x:0, y:0, opacity:0 },
      {id:'no_gas'      , x:0, y:0, opacity:0 },
    ]

    if( ev_state.arInitState === ARInitState.INITIAL){
      ev_state.setARInitState(ARInitState.TAP_TO_PLACE )
    }
  }
  

  stop(): void {
    ev_hotspots.list = []
  }
  

  async load(): Promise<void> {
    
    if( this._loadStarted ) return
    this._loadStarted = true

    await Promise.all([

      this.carscene.load(),
      this.plugscene.load(),
      this.module.iblMngr.loadDefault(),
      HowlerUtils.loadHowl( this.audioIn   ),
      HowlerUtils.loadHowl( this.audioHold ),
      HowlerUtils.loadHowl( this.audioOut  ),
    ])
    //console.log( this.audioHold );
    
    const node : GainNode = (this.audioHold as any)._sounds[0]._node
    this.lowpassFilter = Howler.ctx.createBiquadFilter()
    this.lpGain = Howler.ctx.createGain()
    this.lowpassFilter.type = 'lowpass'
    this.lowpassFilter.gain.value = 0

    node.disconnect()
    node.connect( this.lowpassFilter )
    this.lowpassFilter.connect( this.lpGain )
    this.lpGain.connect( Howler.ctx.destination )


    

    this.hotspotProjector = new ExteriorProjector( this.carscene.gltf.getElementByName(GltfTypes.NODE, 'hotspots') )

    this.ctrlNode.add(this.carscene.gltf.root)
    this.ctrlNode.add(this.plugscene.gltf.root)
    
    ev_state.setExteriorLoaded( true )
  }
  

  preRender(): void {
    const wasPlaced = this.arControler.isPlaced
    this.arControler.update()

    if( this.arControler.isPlaced && !wasPlaced ){
      // just placed
      this.setRootNodeInitialOrientation()
    }

    if( ev_state.arInitState === ARInitState.TAP_TO_PLACE && this.arControler.isPlaced ){
      ev_state.setARInitState( ARInitState.PLUG_INTRO )
    }

    this.carscene.preRender()

    if( ev_state.arInitState === ARInitState.PLUG_INTRO || ev_state.arInitState === ARInitState.CHARGING )
      this.plugscene.preRender()


    if( ev_state.arInitState === ARInitState.CHARGING ){
      // console.log( (this.audioHold as any).addFilter )
      const p = easeInQuad(ev_state.plugCharge)
      this.lowpassFilter.frequency.setValueAtTime( 100 + p * 10000 , Howler.ctx.currentTime);
    }

  }

  
  
  rttRender(): void {
    
    if( ev_state.arInitState === ARInitState.PLUG_INTRO || ev_state.arInitState === ARInitState.CHARGING )
      this.plugscene.rttRender()
   
    if( ev_state.arInitState === ARInitState.REVEAL || ev_state.arInitState === ARInitState.READY )
      this.carscene.rttRender()
    
  }
  
  
  render(): void {

    this.hotspotProjector.projectHotspots(ev_hotspots.list, this.module.scene.camera )
    
    if( ev_state.arInitState === ARInitState.PLUG_INTRO || ev_state.arInitState === ARInitState.CHARGING )
      this.plugscene.render()
      
      
    if( ev_state.arInitState === ARInitState.REVEAL || ev_state.arInitState === ARInitState.READY )
      this.carscene.render()
  }


  setRootNodeInitialOrientation() {
    const v = vec3.create()  
    this.ctrlNode.updateWorldMatrix()
    const camPos = this.module.scene.camera._wposition as vec3
    const pos = this.ctrlNode._wposition as vec3
    vec3.subtract( v, camPos, pos )
    const angle = -Math.atan2( v[2], v[0]) + Math.PI/2
    quat.rotateY( this.ctrlNode.rotation, quat.create(), angle - Math.PI/4 )
    this.ctrlNode.invalidate()
  }

}