















import Vue from "vue";
import Component from "vue-class-component";
import { LocaleMessage, TranslateResult } from "vue-i18n";
import { Prop } from "vue-property-decorator";
import RoundClose from "!vue-svg-loader!@/assets/icons/round-close.svg";
import ebn_state from "@/store/modules/ebn_state";
import ev_market from "@/store/modules/ev_market";
import TrackingEv from "@/core/Tracking";

@Component({
  components: {
    RoundClose,
  },
})
export default class EVCookiesBanner extends Vue {
  get cookies(): LocaleMessage {
    return this.$t("cookies");
  }

  get cookiesOpen() {
    return ebn_state.showCookies;
  }

  get isMobile() {
    const isDesktopMQ = window.matchMedia("only screen and (pointer: coarse)");
    return isDesktopMQ.matches;
  }

  get goodMarket() {
    return ev_market.currentMarket == "ca";
  }

  openPrivacyLink() {
    TrackingEv.openPrivacyLink();
  }

  closeCookies() {
    TrackingEv.closeCookies();
    ebn_state.showBannerCookies(false);
  }
}
