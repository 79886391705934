
 export const BodyColors = [
  'white',
  'blue' ,
  'red'  ,
  'grey' ,
] as const

 export const InteriorColors = [
  'white',
  'dark' ,
] as const

export type BodyColor = typeof BodyColors[number] 
export type InteriorColor = typeof InteriorColors[number] 



type BodyCfg = {
  picto: string
  diff : number
  spec : number
  gloss : number
}


export const BodyColorConfigs: Record<BodyColor, BodyCfg> = {

  white: {
    picto: '#D6D5D2',
    diff: 0xffffff,
    spec: 0x606060,
    gloss: .5
  },
  blue: {
    picto: '#3090E9',
    diff: 0x000018,
    spec: 0x0095d9,
    gloss: .67
  },
  red: {
    picto: '#D73344',
    diff: 0x260000,
    spec: 0xd40000,
    gloss: .67
  },
  grey: {
    picto: '#747C80',
    diff: 0x000000,
    spec: 0x666665,
    gloss: .67
  },
}
