
const AR8thLoader = {

  awaitLibrary(): Promise<void> {

/////////////////
///////////////////////////////////////////////////////
////////////////////////////////
///////
////////////////
      
      if (window.XR8) {
      return Promise.resolve()
    } else {
      return new Promise( (resolve)=>{
        window.addEventListener('xrloaded', ()=>resolve() );
      })
    }
  }

}

export default AR8thLoader