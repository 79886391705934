import ev_market from '@/store/modules/ev_market';

export enum CarModel {
  LT2 = "LT2",
  PREMIER = "Premier",
}

export default function GetModel(): CarModel{
  // return CarModel.LT2
  switch (ev_market.currentMarket) {
    
    case 'mx':
      return CarModel.LT2
      
    default:
      return CarModel.PREMIER

  }
}