import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'
import { UspPanelData } from '../models/UspPanelData'
import ev_usps from './ev_usps'
import { BodyColor, InteriorColor } from '../models/BodyColors'
import Tracking from '@/core/Tracking'
import _datas from "../datas/usps.json"

export enum ViewType {
  INSIDE, 
  OUTSIDE
}

export enum ARInitState {
  INITIAL = "INITIAL", 
  SCAN_AREA = "SCAN_AREA", 
  TAP_TO_PLACE = "TAP_TO_PLACE",
  PLUG_INTRO = "PLUG_INTRO",
  CHARGING = "CHARGING",
  REVEAL = "REVEAL",
  READY = "READY"
}

export enum Market {
  CANADA = "ca",
  MEXICO = "mx",
  SOUTH_KOREA = "kr",
  MEO = "meo"
}


@Module
export class EvState extends VuexModule {
  


  // ======================================================
  
  public viewType: ViewType = ViewType.OUTSIDE
  
  @Action
  setViewType( v:ViewType ) {
    if( this.arInitState !== ARInitState.READY ) return;
    this._SET_VIEW_TYPE(v)
  }

  @Mutation
  private _SET_VIEW_TYPE(v:ViewType) {
    this.viewType = v
  }
  
  
  // ======================================================
  
  public introDone: boolean = false
  
  @Action
  setIntroDone( v:boolean ) {
    this._SET_INTRO_DONE(v)
  }
  
  @Mutation
  private _SET_INTRO_DONE(v:boolean) {
    this.introDone = v
  }
  
  
  // ======================================================
  
  public uIExpShown: boolean = false
  
  @Action
  setuIExpShown( v:boolean ) {
    this._SET_UI_EXP_SHOWN(v)
  }
  
  @Mutation
  private _SET_UI_EXP_SHOWN(v:boolean) {
    this.uIExpShown = v
  }
  
  
  // ======================================================
  
  public isLoading: boolean = false
  
  @Action
  setIsLoading( v:boolean ) {
    this._SET_IS_LOADING(v)
  }
  
  @Mutation
  private _SET_IS_LOADING(v:boolean) {
    this.isLoading = v
  }
  
  // ======================================================
  
  public isFPSWarning: boolean = false
  
  @Action
  setIsFPSWarning( v:boolean ) {
    this._SET_IS_FPS_WARNING(v)
  }
  
  @Mutation
  private _SET_IS_FPS_WARNING(v:boolean) {
    this.isFPSWarning = v
  }
  
  
  // ======================================================
  
  public exteriorLoaded: boolean = false
  
  @Action
  setExteriorLoaded( v:boolean ) {
    this._SET_EXTERIOR_LOADED(v)
    this._SET_IS_LOADING(false)
  }
  
  @Mutation
  private _SET_EXTERIOR_LOADED(v:boolean) {
    this.exteriorLoaded = v
  }
  
  
  
  // ======================================================

  public menuOpened: boolean = false
  
  @Action
  setMenuOpened( v:boolean ) {
    if( v )Tracking.openMenu()
    
    this._SET_MENU_OPENED(v)
  }
  
  @Mutation
  private _SET_MENU_OPENED(v:boolean) {
    this.menuOpened = v
  }
  
  // ======================================================

  public interiorLoaded: boolean = false
  
  @Action
  setInteriorLoaded( v:boolean ) {
    this._SET_INTERIOR_LOADED(v)
    this._SET_IS_LOADING(false)
  }
  
  @Mutation
  private _SET_INTERIOR_LOADED(v:boolean) {
    this.interiorLoaded = v
  }


  // ======================================================

  public currentUsp: UspPanelData = null

  @Action
  openUspId( id:string ) {
    this._SET_CURRENT_USP(ev_usps.find(id) )
  }

  @Action
  openUsp( v:UspPanelData ) {
    this._SET_CURRENT_USP(v)
  }
  
  @Action
  closeUsp( ) {
    this._SET_CURRENT_USP(null)
  }

  @Mutation
  private _SET_CURRENT_USP(v:UspPanelData) {
    this.currentUsp = v
    
    if( v !== null ){
      const data = _datas.find(usp => usp.id == v.id)
      Tracking.openUsp( this.viewType === ViewType.INSIDE, data.title )
    }
  }

  // ======================================================
  
  public bodyColor: BodyColor = 'white'

  @Action
  setBodyColor( v:BodyColor ) {
    this._SET_BODYCOLOR(v)
  }
  @Mutation
  private _SET_BODYCOLOR(v:BodyColor) {
    this.bodyColor = v
  }

  // ======================================================
  
  public interiorColor: InteriorColor = 'white'

  @Action
  setInteriorColor( v:InteriorColor ) {
    this._SET_INTERIORCOLOR(v)
  }
  @Mutation
  private _SET_INTERIORCOLOR(v:InteriorColor) {
    this.interiorColor = v
  }


  // ======================================================

  // public arInitState: ARInitState = ARInitState.REVEAL
  public arInitState: ARInitState = ARInitState.INITIAL

  @Action
  setARInitState( v:ARInitState ) {
    this._SET_ARINITSTATE(v)
  }
  @Mutation
  private _SET_ARINITSTATE(v:ARInitState) {
    //console.log('new ar init state', v);
    
    this.arInitState = v
  }


  // ======================================================
  
  public isCharging:boolean = false

  @Action
  setIsCharging( b:boolean ) {
    this._SET_IS_CHARGING(b)
  }
  @Mutation
  private _SET_IS_CHARGING(b:boolean) {
    this.isCharging = b
  }


  // ======================================================
  
  public plugCharge = 0

  @Action
  setPlugCharge( v:number ) {
    this._SET_PLUG_CHARGE(v)
  }
  @Mutation
  private _SET_PLUG_CHARGE(v:number) {
    this.plugCharge = v
  }


}

const ev_state = new EvState({ store, name: 'ev_state' })

export default ev_state
