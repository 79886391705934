import { HotspotData } from "@/store/modules/ev_hotspots";
import {  vec2, vec4 } from "gl-matrix";
import Camera from "nanogl-camera";
import { IProjector } from "./IProjector";

import _coords from "./interiorHotspots"


// type Coords = typeof coords
type Coords = Record<string,{x:number; y:number}>

const coords = _coords as Coords

const V4A = vec4.create()
const V2A = vec2.create()


function latLngToWorldPos(out: vec4, latlng: vec2): void {
  const theta = latlng[0] * Math.PI * 2
  const phi = (latlng[1] - 0.5) * Math.PI

  out[0] = Math.cos(phi) * Math.sin(theta)
  out[2] = Math.cos(phi) * -Math.cos(theta)
  out[1] = Math.sin(phi)
  out[3] = 1

}

export default class InteriorProjector implements IProjector{


  projectHotspots( list: HotspotData[], camera : Camera ): void {

    for (const h of list) {

      const n = coords[h.id]
      if( !n ) throw `unknown int  hotspot ${h.id}`
      

      V2A[0] = n.x/4096 + .5
      V2A[1] = 1.0 - n.y/2048

      latLngToWorldPos(V4A, V2A)
      vec4.transformMat4(V4A, V4A, camera._viewProj)
    
      h.x = (V4A[0] / V4A[3]) * 0.5 + 0.5
      h.y = -(V4A[1] / V4A[3]) * 0.5 + 0.5
      h.opacity = (V4A[3] > 0)?1:0
    }
  }

}