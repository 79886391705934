
























import Vue from "vue";
import Component from "vue-class-component";
import ev_state from "@/store/modules/ev_state";
import gsap from "gsap";
import { Sine } from "gsap/all";
import { LocaleMessage } from 'vue-i18n';
import ev_market from '@/store/modules/ev_market';

@Component({
  components: {},
})
export default class EVChargingUI extends Vue {
  timeline: gsap.core.Timeline;

  get keepHolding(): LocaleMessage {
    return this.$t("keep-holding")
  }

  get mx(): boolean {
    return ev_market.currentMarket == "mx";
  }

  mounted() {
    gsap.set(".ev-charging-button-middle-label", { opacity: 0 });
    gsap.to(".ev-charging-button-middle-label", {
      duration: 1,
      opacity: 1,
    });
    gsap.to(".ev-charging-ui", {
      duration: 0.5,
      opacity: 1,
      ease: Sine.easeInOut,
    });
    this.timeline = gsap.timeline({ paused: true });
    this.timeline
      .to(".ev-charging-button", {
        duration: 5,
        width: "100vw",
        height: "100vw",
        ease: Sine.easeInOut,
      })
      .to(
        ".ev-charging-button-middle-label",
        {
          duration: 1,
          opacity: 0,
        },
        "<"
      )
      .to(
        ".ev-charging-button-middle",
        {
          duration: 5,
          width: "calc(100vw - 36px)",
          height: "calc(100vw - 36px)",
          ease: Sine.easeInOut,
        },
        "<"
      )
      .to(
        ".ev-charging-button-end-label",
        {
          duration: 5,
          scaleX: 1.2,
          scaleY: 1.2,
        },
        "<"
      )
      .to(
        ".ev-charging-button-end-label",
        {
          duration: 1,
          opacity: 1,
        },
        "<+1"
      )
      .to(
        ".ev-charging-button-end",
        {
          duration: 2,
          width: "56px",
          height: "56px",
          ease: Sine.easeInOut,
        },
        "<"
      )
      .to(
        ".ev-charging-button",
        {
          duration: 2,
          opacity: 0,
        },
        ">+1"
      )
      .to(
        ".ev-charging-bg",
        {
          duration: 2,
          opacity: 0,
        },
        "<"
      );

    // this.timeline.progress(0).pause();

    ev_state.$watch(
      (s) => s.plugCharge,
      (plugCharge) => {
        document.body.classList.add("disable-select");
        this.timeline.progress(plugCharge);
        // if (plugCharge === 1) this.$track("hold-plug", { "data-dtm": "intro" });
        // console.log(this.timeline.progress());
      }
    );
  }

  startCharging(e: Event) {
    e.preventDefault();
    ev_state.setIsCharging(true);
  }

  stopCharging() {
    ev_state.setIsCharging(false);
  }
}
