






























































import Vue from "vue";
import Component from "vue-class-component";
import LottieAnimation from "../LottieAnimation/LottieAnimation.vue";
import ev_state from "@/store/modules/ev_state";
import Delay from "@/core/Delay";

import gsap from "gsap";
import { LocaleMessage } from "vue-i18n";
import ev_market from "@/store/modules/ev_market";

@Component({
  components: {
    LottieAnimation,
  },
})
export default class EvIntro extends Vue {
  x: string;
  videoReady: boolean;
  timerDone: boolean;
  currentStep: number;

  logoVideo: HTMLVideoElement = null;
  introVideo: HTMLVideoElement = null;

  exteriorLoaded: boolean;
  animDone: boolean;
  percLoad: number = 0;

  get modelDisclaimer(): LocaleMessage {
    return this.$t("model_disclaimer");
  }

  get loading(): LocaleMessage {
    return this.$t("loading");
  }

  get d1(): LocaleMessage {
    return this.$t("intro.d1")
  }

  get meo() {
    return ev_market.currentMarket == "meo";
  }

  get rtl() {
    return ev_market.currentLocale == "ar-ae";
  }

  async mounted() {
    if (process.env.VUE_APP_SKIP_PRELOAD === "true") {
      ev_state.setIntroDone(true);
      this.$emit("intro-done");
      return;
    }

    this.percLoad = 0;
    this.currentStep = 0;
    this.exteriorLoaded = this.animDone = false;

    this.$brightcovePlayerLoader({
      refNode: this.$refs.logoVideo,
      refNodeInsert: "append",
      accountId: "6053147451001",
      playerId: "default",
      videoId: "6282344979001",
      autoplay: true,
      playsinline: true,
      muted: true,
    }).then(() => {
      this.logoVideo = (this.$refs.logoVideo as HTMLElement).querySelector(
        "video"
      );
      const player = (this.logoVideo.parentNode as any).player;
      player.controlBar.progressControl.disable();
      player.ready(async () => {
        this.logoVideo.playsInline = true;
        this.logoVideo.muted = true;
        this.logoVideo.addEventListener("canplaythrough", this.logoCanPlay);
        player.controlBar.progressControl.disable();
        this.logoVideo.load();
        await Delay(500);
        (this.$refs.logoVideo as HTMLElement).classList.add("show");
        this.logoVideo.pause();
        this.logoVideo.play();
        await Delay(500);
        (this.$refs.waitingLogo as HTMLElement).classList.add("hide");
      });
    });

    this.$brightcovePlayerLoader({
      refNode: this.$refs.introVideo,
      refNodeInsert: "append",
      accountId: "6053147451001",
      playerId: "default",
      embedId: "default",
      videoId: ev_market.currentMarket == "mx" ? "6285803810001" : "6282347780001",
    }).then(() => {
      this.introVideo = (this.$refs.introVideo as HTMLElement).querySelector(
        "video"
      );
      const player = (this.introVideo.parentNode as any).player;
      player.ready(async () => {
        this.introVideo.playsInline = true;
        this.introVideo.muted = true;
        this.introVideo.load();
        this.introVideo.pause();
        this.introVideo.addEventListener("ended", this.videoDone);
        await Delay(1000);
        //this.introVideo.addEventListener("canplaythrough", this.videoCanPlay);
        this.introVideo.pause();
        this.introVideo.play();
        await Delay(2750);
        (this.$refs.bluewaves as LottieAnimation).play();
      });
    });

    ev_state.$watch(
      (s) => s.exteriorLoaded,
      (isLoaded) => {
        if (isLoaded) {
          this.exteriorLoaded = true;
          this.animEnd();
        }
      }
    );
  }
  beforeDestroy() {
    if (this.logoVideo)
      this.logoVideo.removeEventListener("canplaythrough", this.logoCanPlay);

    if (this.introVideo) {
      this.introVideo.removeEventListener("ended", this.videoDone);
      this.introVideo.removeEventListener("canplaythrough", this.videoCanPlay);
    }

    this.$brightcovePlayerLoader.reset();
  }

  videoCanPlay() {
    this.timerDone ? this.goNextStep() : this.videoReady = true;
  }

  videoDone() {
    this.goNextStep();
  }

  async logoCanPlay() {
    this.handleCurrentState();
  }

  goNextStep() {
    this.currentStep++;
    this.handleCurrentState();
  }

  async handleCurrentState() {
    switch (this.currentStep) {
      case 0:
        this.$el.classList.add("step-1");

        await Delay(4050);
        this.$el.classList.add("video-done");

        await Delay(500);
        //console.log("step 1")
        if (this.videoReady) this.goNextStep();
        else this.timerDone = true;

        break;
      case 1:
        this.introVideo.play();
        this.$el.classList.add("step-2");
        //console.log("step 2")
        break;
      case 2:
        this.$el.classList.add("step-3");
        // (this.$refs.introVideo as any).play();
        await Delay(500);
        (this.$refs.whitewaves as LottieAnimation).play();
        gsap.to(this, 2, { percLoad: 100 });
        await Delay(3000);
        this.goNextStep();
        break;
      case 3:
        this.animDone = true;
        this.animEnd();
        break;
    }
  }

  async animEnd() {
    if (this.exteriorLoaded && this.animDone) {
      this.$el.classList.add("step-4");
      await Delay(100);
      ev_state.setIntroDone(true);
      this.$emit("intro-done");
    }
  }
}
