
import Paths from "@/core/Paths";
import GltfModule from "../GltfModule";
import EVModule from "../../EVModule";
import CircleLines, { CircleConfig } from "./CircleLines";
import Particles from "./Particles";
import UnlitMaterial from "@/webgl/lib/nanogl-gltf/lib/extensions/KHR_materials_unlit/UnlitMaterial";
import GltfTypes from "@/webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import Chunk from "nanogl-pbr/Chunk";
import ChunksSlots from "nanogl-pbr/ChunksSlots";
import Program from "nanogl/program";
import Node from "nanogl-node";
import { clamp, clamp01, remapProgress } from "@/webgl/math";
import { easeOutBack, easeOutCubic, linear } from "@/webgl/math/ease";
import { StandardPass } from "nanogl-pbr/StandardPass";
import { MetalnessSurface } from "nanogl-pbr/PbrSurface";
import Material from "@/webgl/lib/nanogl-gltf/lib/elements/Material";
import { Uniform } from "nanogl-pbr/Input";
import ev_state, { ARInitState } from "@/store/modules/ev_state";
import quat from "gl-matrix/src/gl-matrix/quat";
import gsap from "gsap/all";
import UnlitPass from "nanogl-pbr/UnlitPass";
import Tracking from "@/core/Tracking";
import { Howl } from "howler";
import { safari } from '@/core/Browser';


const Q_ID = quat.create()

class PcircleChunk extends Chunk {
  progress: number;
  constructor(){
    super(true, true)
    this.progress = 0
  }
  
  setup( prg:Program){
    prg.upp( this.progress )
  }
  
  protected _genCode(slots: ChunksSlots): void {
    slots.add( 'pf', 'IN vec2 vtc;')
    slots.add( 'pf', 'uniform float upp;')
    slots.add( 'pv', 'OUT vec2 vtc;')
    slots.add( 'v', 'vtc = aTexCoord0;')
    slots.add( 'f', 'if( vtc.x > upp ) discard;')
  }
  
}

const LINES : CircleConfig[] = [        
{
  radius : 1.0, 
  thickness : .8,
  height: 0
},
{
  radius : 1.1, 
  thickness : .3,
  height: 0
},
{
  radius : 1.2, 
  thickness : .8,
  height: 0
},
{
  radius : 1.3, 
  thickness : .3,
  height: 0
},
{
  radius : 1.4, 
  thickness : .8,
  height: 0
}]



export default class PlugScene extends GltfModule {
  

  pluganim = 0

  circles: CircleLines;
  dots: Particles;
  pcircleChunk: PcircleChunk;
  female: Node;
  plug: Node
  femaleEmmissive: Uniform;
  depthCutCore: Node
  inprint: Node
  chargeSpeed = 0;
  inprintColorFactor: Uniform;



  
  constructor(private module: EVModule) {
    super(module.scene, Paths.resolve(`assets/webgl/gltf/PlugScene.gltf`), module.iblMngr)

    this.circles = new CircleLines( module.scene)
    this.dots = new Particles( module.scene)


    this.pcircleChunk = new PcircleChunk()
    // window.addEventListener('click', ()=>this.playPlugAnim())


  }

  load(): Promise<void> {
    return Promise.all([
      super.load(),
      this.circles.load(),
      this.dots.load()
    ]).then()
  }

  playPlugAnim(){
    this.pluganim = 0
    gsap.killTweensOf(this)
    gsap.to( this, {pluganim:1, duration:2.5, ease:linear, onComplete:()=>this.plugAnimComplete()})
  }
  
  plugAnimComplete(): void {
    ev_state.setARInitState( ARInitState.CHARGING )
  }

  preRender(){

    if( ev_state.arInitState === ARInitState.CHARGING ){
      
      if(ev_state.isCharging){

        if( this.chargeSpeed < 0 ) {
          Tracking.holdPlug()
          this.chargeSpeed = 0
        }
        this.chargeSpeed += this.scene.dt
      } else {
        this.chargeSpeed -= this.scene.dt
      }

      this.chargeSpeed = clamp(this.chargeSpeed, -.5, 1)

      let charge = ev_state.plugCharge + (this.chargeSpeed*this.scene.dt)

      charge = clamp01(charge)
      ev_state.setPlugCharge( charge )
    
      if( charge > 0.9999 ) {
        ev_state.setARInitState( ARInitState.REVEAL )
      }
    }

    // this.pluganim = this.scene.inputs.mouseCoords[0]*.5 + .5

    this.pcircleChunk.progress = remapProgress( this.pluganim, 0, .6 )

    let femalep = remapProgress( this.pluganim, 0.6, 1.0 )
    femalep = easeOutCubic(femalep)
    const femalelow = -0.06
    this.female.y = femalelow -femalelow * femalep;

    this.depthCutCore.y = -.08 * remapProgress( this.pluganim, .6, .8 )
    // this.inprint.y = -.1 * remapProgress( this.pluganim, .7, .9 )
    const f = 1.0-remapProgress( this.pluganim, .7, .9 )
    this.inprintColorFactor.set(f, f, f)
    if( this.pluganim > 0.9 ) {
      this.inprint.y = 150
      this.pcircleChunk.progress = 0
    }
    
    
    let circlesp = remapProgress( this.pluganim, 0.6, 1.0 )
    circlesp = easeOutBack(circlesp) *.8
    this.circles.node.setScale(circlesp)
    this.dots.node.setScale(circlesp)

    const fe = 1.0 - remapProgress( this.pluganim, 0.6, .75 )

    this.femaleEmmissive.set( fe, fe, fe )



    this.plug.y = .5 - .5*ev_state.plugCharge
    
    const plugAppear = easeOutCubic( remapProgress( this.pluganim, 0.8, 1 ) )
    this.plug.y += 1 - plugAppear * 1
    this.plug.setScale( plugAppear )


    quat.rotateX(  this.plug.rotation, Q_ID, -Math.PI/2)
    quat.rotateZ(  this.plug.rotation, this.plug.rotation, 3 - 3*ev_state.plugCharge )

    

    super.preRender()
  }

  render(){

    // this.progress = this.scene.inputs.mouseCoords[0]*.5 + .5

    const p = ev_state.plugCharge


    super.render()

    const configs = LINES.map( (l,i):CircleConfig =>{
      return {
        radius: l.radius + .3 * i * p,
        thickness: l.thickness,
        height: .5 * i * p
      }
    })

    if( this.pluganim > .6 ){
      this.circles.render(configs)
      this.dots.render(p)
    }
  }
  
  onLoaded(): void {

    const gl = this.scene.gl

    this.circles.node.y = .1
    this.gltf.root.add( this.circles.node)
    this.gltf.root.add( this.dots.node)



    this.plug = this.gltf.getElementByName(GltfTypes.NODE, "plug") 
    this.female = this.gltf.getElementByName(GltfTypes.NODE, "female") 
    this.depthCutCore = this.gltf.getElementByName(GltfTypes.NODE, "depth_cut_core") 
    this.inprint = this.gltf.getElementByName(GltfTypes.NODE, "inprint") 
    

    const inprint = this.gltf.getElementByName(GltfTypes.MATERIAL, "inprint") as UnlitMaterial
    inprint.materialPass.glconfig
      .depthMask(false)
      .enableBlend()
      .blendFunc(gl.ONE, gl.ONE)

    const inprintPass = inprint.materialPass as UnlitPass
    this.inprintColorFactor = inprintPass.baseColorFactor.attachUniform('azeaze')
    
    
    const pcircle = this.gltf.getElementByName(GltfTypes.MATERIAL, "pcircle") as UnlitMaterial
    pcircle.materialPass.glconfig
      .enableBlend()
      .depthMask(false)
      .blendFunc(gl.ONE, gl.ONE)
    pcircle.materialPass.inputs.add( this.pcircleChunk )
    
    const depth_cut = this.gltf.getElementByName(GltfTypes.MATERIAL, "depth_cut") as UnlitMaterial
    depth_cut.materialPass.glconfig
      .depthMask(true)
      .colorMask(false, false, false, false)
    
    const black_female = this.gltf.getElementByName(GltfTypes.MATERIAL, "prise femelle") as Material
    const pass = black_female.materialPass as StandardPass<MetalnessSurface>
    this.femaleEmmissive = pass.emissive.attachUniform('uemissive')
    // black_female.materialPass.glconfig
    //   .depthMask(true)
    //   .colorMask(false, false, false, false)
      
  }
}

