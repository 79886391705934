import { SchemeColorProp, SchemeColorsType } from '@/core/SchemeColor'
import ebn_state, { EBNMainState } from '@/store/modules/ebn_state'
import Vue from 'vue'


/**
 * return class name defining the color variants for the given color
 * eg 
 * primaryClass('blue') // => primary-blue
 * ```
 * .primary-blue {
 *   --primary-color: var(--primary-blue);
 *   --primary-light-color: var(--primary-light-blue);
 *   --primary-dark-color: var(--primary-dark-blue);
 * }
 * ``` 
 */
export function primaryClass( color: SchemeColorsType ){
  return `primary-${color}`
}


/**
 * Mixin to 
 *  - add `color` property to components
 *  - provide classname to add to element to define css vars defining color schemes (@see primaryClass )
 *  - add `inheritColor` property to ignore `color` prop and use ascendants vars
 */
const SchemeColoredMixin = Vue.extend({

  props: {
    color : {
      ...SchemeColorProp, 
      default:undefined
    }
  },

  computed: {

    schemeClass(){
      if(ebn_state.mainState === EBNMainState.CAROUSEL) {
        return `primary-${ebn_state.carouselChar}`
      } else if( this.color ) {
        return primaryClass( this.color as SchemeColorsType )
      }
      return null
    }
    
  }
})

export default SchemeColoredMixin;