import Scene from "@/webgl/Scene";
import Fbo, { Attachment } from "nanogl/fbo";
import type CarScene from "./CarScene";


import reveal_VS from './reveal_blit.vert'
import reveal_FS from './reveal_blit.frag'
import reveal_fx_VS from './reveal_fx.vert'
import reveal_fx_FS from './reveal_fx.frag'
import Program from "nanogl/program";
import { LocalConfig } from "nanogl-state";
import { mat4 } from "gl-matrix";
import { TmpHexToVec } from "@/webgl/lib/color";


const M4 = mat4.create()


const SUBSCALE = 1



export default class RevealEffect {

  mainFbo : Fbo
  waveFbo : Fbo
  linesFbo: Fbo

  depthAttachment: Attachment
  reveal_prg: Program
  fx_prg    : Program

  progress = 0
  fxCfg  : LocalConfig
  blitCfg: LocalConfig

  constructor( private scene:Scene ){

    

    const gl = scene.gl
    this.mainFbo = new Fbo(gl)
    this.mainFbo.resize(16, 16)
    this.mainFbo.attachColor( gl.RGBA, gl.UNSIGNED_BYTE )
    this.depthAttachment = this.mainFbo.attachDepth( true, false, false )
    this.mainFbo.getColorTexture().clamp()
    
    this.linesFbo = new Fbo(gl)
    this.linesFbo.resize(16, 16)
    this.linesFbo.attachColor( gl.RGB, gl.UNSIGNED_BYTE )
    this.linesFbo.attach( gl.DEPTH_ATTACHMENT, this.depthAttachment.target )
    this.linesFbo.getColorTexture().clamp()
    
    this.waveFbo = new Fbo(gl)
    this.waveFbo.attachColor( gl.RGB, gl.UNSIGNED_BYTE )
    this.waveFbo.resize(16, 16)
    // this.waveFbo.attach(gl.DEPTH_ATTACHMENT, this.depthAttachment.target )
    
    this.waveFbo.attachDepth( true, false )
    this.waveFbo.getColorTexture().clamp()


    this.reveal_prg = this.scene.programs.create('reveal_blit', reveal_VS(), reveal_FS())
    this.fx_prg     = this.scene.programs.create('reveal_fx', reveal_fx_VS(), reveal_fx_FS())

    this.blitCfg = scene.glstate.config()
      .enableBlend()
      .blendFunc( gl.ONE, gl.ONE_MINUS_SRC_ALPHA )

    this.fxCfg = scene.glstate.config()
      .enableDepthTest(true)
      .depthFunc( gl.LEQUAL )
      .enableCullface(true)
      .polygonOffset( 0, 10000 )

  }
  
  rttPass( carscene : CarScene ){
    const gl = this.scene.gl
    const w = this.scene.glview.width >>> SUBSCALE
    const h = this.scene.glview.height >>> SUBSCALE

    this.waveFbo .resize( w, h )
    this.linesFbo.resize( w, h )
    this.mainFbo .resize( w, h )



    gl.viewport(0, 0, w, h)

    this.mainFbo.bind()
    gl.clearColor( 0, 0, 0, 0 );
    gl.clear( gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT )
    this.scene.blitArCamera()
    carscene.renderCarOnly()

    
    
    this.linesFbo.bind()
    gl.clear(gl.COLOR_BUFFER_BIT)
    carscene.renderCircles()
    this.scene.glstate.apply()
    
    
    
    this.waveFbo.bind()
    
    gl.clearColor(0, 0, 0, 0);
    gl.clear( gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT )


    this.fx_prg.use()
    // range a start, range a width
    // range b start, range b width


    const cam = carscene.getCurrentCamera()

    this.fx_prg.uRanges( 
      -7 + this.progress* 14, 4, 
      -8 + this.progress* 14, 2
    )
    mat4.invert( M4, carscene.gltf.root._wmatrix )
    this.fx_prg.uRefMatrix( M4 )
    this.fx_prg.uCamPos( cam._wposition )

    this.fxCfg.apply()
    carscene.renderProgram( cam , this.fx_prg )


  }


  blit(){
    this.reveal_prg.use()

    this.blitCfg.apply()

    this.reveal_prg.uWaveColor( TmpHexToVec(0x47a4f6) )
    this.reveal_prg.tMain( this.mainFbo.getColorTexture())
    this.reveal_prg.tMask( this.waveFbo.getColorTexture())
    this.reveal_prg.tLines( this.linesFbo.getColorTexture())
    this.scene.quad.attribPointer( this.reveal_prg )
    this.scene.quad.render()

  }

}