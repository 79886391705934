import Vue from 'vue'
import { ComponentOptions } from "vue/types/umd"

const _modules = require.context( '!vue-svg-loader?{"svgo": {"plugins": [{ "prefixIds": true }, { "removeViewBox": false }]}}!./', false, /.*\.svg$/i )

const _names: string[] = []
const _lib: Record<string, ComponentOptions<Vue>> = {}

_modules.keys().forEach( k=>{
  const id = k.substring(2, k.length-4)
  _lib[id] = _modules(k)
  _names.push(id)
})

const icons = function( icon: string ){
  if( _lib[icon] === undefined ){
    throw `Icon "${icon}" not found`
  }
  return _lib[icon]
}

icons.names = function(){
  return _names.concat()
}

icons.register = function(){
  for (const key in _lib) {
    Vue.component( `icon-${key}`, _lib[key] )
  }
}

export default icons;