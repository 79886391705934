import Scene from "@/webgl/Scene";
import { LocalConfig } from "nanogl-state";
import ArrayBuffer from "nanogl/arraybuffer";

import VS from './dots.vert'
import FS from './dots.frag'
import Program from "nanogl/program";
import Paths from "@/core/Paths";
import LazyTexture from "@/webgl/gl/LazyTexture";
import { TmpHexToVec } from "@/webgl/lib/color";
import Node from "nanogl-node";
import glowTint from "./glowtint";

/*
  angle, angular_speed, orbit, size
*/
const FLOAT_PER_P = 4

const NUM_P = 32


export default class Particles {
  vbuffer: ArrayBuffer;
  cfg: LocalConfig;
  prg: Program;
  dotTex: LazyTexture;
  node: Node;



  constructor( private scene: Scene){

    const gl = scene.gl

    this.node = new Node()

    const vdata = new Float32Array( FLOAT_PER_P * NUM_P )

    for (let i = 0; i < NUM_P; i++) {
      const j = i*FLOAT_PER_P
      vdata[j+0] = Math.random() * Math.PI*2
      vdata[j+1] = Math.round( 5 + Math.random()*5 )
      vdata[j+2] = 0 + Math.random()
      vdata[j+3] = .1*(.5 + Math.random()*.5)
    }

    this.vbuffer = new ArrayBuffer( scene.gl, vdata )
      .attrib( 'aData', FLOAT_PER_P, gl.FLOAT )

    this.dotTex = new LazyTexture( Paths.resolve('assets/webgl/dot.png'), false, false)

    this.cfg = scene.glstate.config()
      .enableBlend()
      .blendFunc( gl.ONE, gl.ONE )
      .depthMask(false)
      .enableDepthTest()

    
    this.prg = this.scene.programs.create('dots', VS(), FS())



  }

  load( ): Promise<void>{
    return this.dotTex.loadAndAllocate( this.scene.gl)
  }

  render(progress:number){
  

    const time = (this.scene.time / 60.0)%1.0 * Math.PI*2.0

    this.prg.use()
    this.vbuffer.attribPointer(this.prg)


    this.prg.uPSizeFactor( this.scene.glview.height * this.scene.camera.lens.getProjection()[5] );

    this.prg.uMVP( this.scene.camera.getMVP( this.node._wmatrix ))
    this.prg.uTex( this.dotTex.texture );
    this.prg.uTint( glowTint.value )
    this.prg.uTime( time );
    this.prg.uProgress( progress );
    // min rad, d rad at p0, d rad at p1, d height
    this.prg.uRadiusParams( [.9, .6, .9, 4*.5] );
    

    this.vbuffer.drawPoints()
  }
}