



























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import UISpinner from "@/components/UISpinner/UISpinner.vue";

import SchemColoredMixin from "@/mixins/SchemeColored";
import ev_market from "@/store/modules/ev_market";

@Component({
  components: {
    UISpinner,
  },
  mixins: [SchemColoredMixin],
})
export default class UIBtn extends Vue {
  /**
   * icon id to display
   */
  @Prop({ type: String }) icon?: string;
  @Prop({ type: String, default: "" }) urlParam?: string;

  /**
   * scheme color, default 'blue'
   * use inheritColor:true to allow btn to inherit perents scheme
   */
  // @Prop({...SchemeColorProp, default:'accent'}) color: SchemeColorsType

  /**
   * if true, `color` prop is ignored and scheme color is inherited from parents components
   */
  // @Prop({type:Boolean, default:false}) inheritColor!: boolean

  /**
   * Primary button, with --primary background color
   */
  @Prop({
    type: Boolean,
    default: false,
  })
  primary!: boolean;

  /**
   * outlined button, with --primary border color and white BG
   */
  @Prop({
    type: Boolean,
    default: false,
  })
  outlined!: boolean;

  /**
   * outlined button, with --primary border color and white BG
   */
  @Prop({
    type: Boolean,
    default: false,
  })
  whiteOutlined!: boolean;

  /**
   * Optional href
   */
  @Prop({
    type: String,
    default: undefined,
  })
  href!: string;

  /**
   * Optional href target
   */
  @Prop({
    type: String,
    default: "_blank",
  })
  target!: string;

  /**
   * compact size (32px height)
   */
  @Prop({
    type: Boolean,
    default: false,
  })
  compact!: boolean;

  /**
   * large size (59px height)
   */
  @Prop({
    type: Boolean,
    default: false,
  })
  large!: boolean;

  /**
   * capsule shape
   */
  @Prop({
    type: Boolean,
    default: false,
  })
  rounded!: boolean;

  /**
   * disabled btn
   */
  @Prop({
    type: Boolean,
    default: false,
  })
  disabled!: boolean;

  /**
   * display a shadow
   */
  @Prop({
    type: Boolean,
    default: false,
  })
  shadow!: boolean;

  /**
   * display a spinner
   */
  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  get _isdisabled() {
    return this.disabled || this.loading;
  }

  get rtl() {
    return ev_market.currentLocale == "ar-ae";
  }

  // get schemeClass(): string{
  //   if( !this.inheritColor ) {
  //     return primaryClass( this.color )
  //   }
  //   return null
  // }

  _onClick() {
    this.$emit("click");
    if (this.href !== undefined) {
      window.open(this.href + this.urlParam, this.target);
    }
  }
}
