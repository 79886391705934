export function isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const uA = (typeof (navigator) !== 'undefined' && navigator.userAgent.toLowerCase()) || ''
export const safari = uA.match(/version\/[\d\.]+.*safari/)