import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'

@Module
export class EVMarket extends VuexModule {
  
  public currentMarket: string = "en";
  public currentLocale: string = "en";
  public allMarkets = process.env.DEBUG == "true" ? process.env.VUE_APP_MARKET_EV : process.env.VUE_APP_MARKET_EV.split(",");

  init() {
    this.market();
    this.locale();
  }

  market() {
    if (process.env.VUE_APP_DEBUG === "true") {
      this.setMarket(process.env.VUE_APP_MARKET_EV);
      return;
    }

    // DEBUG
    for (let i = 0; i < this.allMarkets.length; i++) {
      if(window.location.pathname.includes(`${this.allMarkets[i]}`) || window.location.hostname.includes(`${this.allMarkets[i]}`)) {
        this.setMarket(this.allMarkets[i]);
      }
    }

    // MEO
    if(window.location.hostname.includes('ar')) { 
      this.setMarket("meo");
    }

    if(window.location.hostname.includes('ca')) {
      this.setMarket("ca");
    }
  }

  locale() {
    if (process.env.VUE_APP_DEBUG === "true") {
      this.setLocale(process.env.VUE_APP_CURRENT_LANG_EV);
      return;
    }

    // DEV
    for (let i = 0; i < this.allMarkets.length; i++) {
      if(window.location.pathname.includes(`/${this.allMarkets[i]}/`)) {
        const locale = window.location.pathname.replace(`/${this.allMarkets[i]}/`, '').split(/\s/).join('');
        this.setLocale(locale)
      }
    }

    // CANADA
    if(window.location.hostname.includes('ca')) {
      const locale = window.location.pathname.replace('/', '');
      switch(locale) {
        case "en":
          this.setLocale('en-ca');
          break;
        case "en/":
          this.setLocale('en-ca');
          break;
        case "fr":
          this.setLocale('fr-ca');
          break;
        case "fr/":
          this.setLocale('fr-ca');
          break;
        default:
          this.setLocale('en-ca');
          break;
      }
    }

    // MEO
    if(window.location.hostname.includes('ar')) {
      const locale = window.location.pathname.replace('/', '');
      switch(locale) {
        case "ae-en":
          this.setLocale('en-ae');
          break;
        case "ae-en/":
          this.setLocale('en-ae');
          break;
        case "ae-ar":
          this.setLocale('ar-ae');
          break;
        case "ae-ar/": 
          this.setLocale('ar-ae');
          break;
        case "ar-ae":
          this.setLocale('ar-ae');
          break;
        case "ar-ae/": 
          this.setLocale('ar-ae');
          break;
        default:
          this.setLocale('en-ae');
          break;
      }
    }

    // KR
    if(window.location.hostname.includes('kr')) {
      this.setLocale("ko");
      this.setMarket("kr");
    }

    // MX
    if(window.location.hostname.includes('mx')) {
      this.setLocale("es-mx");
      this.setMarket("mx");
    }
  }

  @Action
  setMarket( v: string ) {
    this._SET_MARKET(v)
  }

  @Mutation
  private _SET_MARKET( v: string ) {
    this.currentMarket = v
  }

  @Action
  setLocale( v: string ) {
    this._SET_LOCALE(v)
  }

  @Mutation
  private _SET_LOCALE( v: string ) {
    this.currentLocale = v
  }
}

const ev_market = new EVMarket({ store, name: 'ev_market' });
ev_market.init();

export default ev_market;