














import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { Color } from "./EVColorPicker.vue";

@Component
export default class EVColorPickerItem extends Vue {
  @Prop({ type: Object, required: true }) color!: Color;
  @Prop({ type: Boolean, required: false }) show!: boolean;
  @Prop({ type: Number, required: false }) i!: number;

  onClick() {
    this.$emit("choose-color", this.color.id);
  }
}
