import { EbnCharacters, EbnCharacter } from "@/store/models/EbnCharacters";

/**
 * List of available Scheme primary colors
 * must match primary colors available as css vars 
 */
export const SchemeColors = [
  'joel',
  'zoe',
  'constance',
  'robert',
] as const



/**
 * List of available Scheme primary colors
 */
export type SchemeColorsType = typeof SchemeColors[number] 



/**
 * Type Guard, test given string is a valid color ident
 */
export function stringIsSchemeColors( s: string ): s is SchemeColorsType {
  return SchemeColors.includes(s as SchemeColorsType);
}

/**
 * Type Guard, asserts given string is a valid color ident
 */
export function assertIsSchemeColors( s: string ): asserts s is SchemeColorsType {
  if( ! stringIsSchemeColors(s) ) throw `Invlid color ${s}`
}


export function getSchemeForCharacter( c: EbnCharacter ): SchemeColorsType {
  return c
}

/**
 * Property description for vue component
 * add validator for eg: a `color=red` property
 */
export const SchemeColorProp = { 
  type:String, 
  validator: (c: SchemeColorsType)=> c===undefined || SchemeColors.includes(c)
}
