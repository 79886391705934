import i18n from "@/locales/ev";
import { VuexModule } from "vuex-class-modules";
import store from "..";
import _datas from "../datas/usps.json"
import _datasKR from "../datas/usps-kr.json"
import _datasMEO from "../datas/usps-meo.json"
import { UspEntryData, UspPanelData } from "../models/UspPanelData";
import ev_market from "./ev_market";

process.env.DEBUG == "true"
  ? i18n.locale = process.env.VUE_APP_CURRENT_LANG_EV
  : i18n.locale = ev_market.currentLocale;

function coerceJson(datas: UspEntryData[] ): UspPanelData[] {

  const errors: string[] = []

  function getContent( id:string, key:string, opt=false ) : string {
    const exist = i18n.te(`usps.${id}.${key}` )
    if( !exist && ! opt ){
      errors.push( `usps local missing "usps.${id}.${key}"` )
    }

    if( exist )
      return i18n.t(`usps.${id}.${key}` ).toString()
    
    return null
  }

  const res = datas.map(e => ({
    id     : e.id,
    link   : getContent(e.id, 'link'  ),
    title  : getContent(e.id, 'title' ),
    text   : getContent(e.id, 'text'  ),
    legals : getContent(e.id, 'legals', true),
    medias : e.medias
  }))

  if( errors.length ){
    console.error(errors.join('\n'));
  }

  return res
  // console.log(JSON.stringify(res, null, 2));
  
}


class EVUsps extends VuexModule {

  list: UspPanelData[] = ev_market.currentMarket == "kr" ? coerceJson(_datasKR) : ev_market.currentMarket == "meo" ? coerceJson(_datasMEO) : coerceJson(_datas);

  async fetch(): Promise<void> {
    return Promise.resolve()
  }

  find( id:string) {
    return this.list.find((usp)=>usp.id === id)
  }

}
const ev_usps = new EVUsps({ store, name: 'ev_usps' })

export default ev_usps;