
















































































import ev_state from "@/store/modules/ev_state";
import Vue from "vue";
import Component from "vue-class-component";
import ChevronLeft from "!vue-svg-loader!@/assets/icons/chevron-left.svg";
import TrackingEv from "@/core/Tracking";
import ev_market from "@/store/modules/ev_market";
import { LocaleMessage } from "vue-i18n";
import ebn_state from "@/store/modules/ebn_state";
import ev_usps from "@/store/modules/ev_usps";

@Component({
  components: {
    ChevronLeft,
  },
})
export default class EVMenuPage extends Vue {

  get seeMore(): LocaleMessage {
    return this.$t("menu.seemore");
  }

  get langs() {
    let l;
    const currentMarket = ev_market.currentMarket;
    const lang = this.$i18n.locale;
    const selected = lang === ev_market.currentLocale;

    if(currentMarket == "meo") {
      l = [
        {
          key: "ar",
          url: "https://bolteuveveffect.chevroletarabia.com/ae-ar/",
          selected: "ar-ae" == ev_market.currentLocale
        }, {
          key: "en",
          url: "https://bolteuveveffect.chevroletarabia.com/ae-en/",
          selected: "en-ae" == ev_market.currentLocale
        }
      ]
    } else if(currentMarket == "ca") {
      l = [
        {
          key: "en",
          url: "https://bolteuveveffect.chevrolet.ca/en/",
          selected: "en-ca" == ev_market.currentLocale
        }, {
          key: "fr",
          url: "https://bolteuveveffect.chevrolet.ca/fr/",
          selected: "fr-ca" == ev_market.currentLocale
        }
      ]
    }

    // for (const [key, value] of Object.entries(this.$i18n.messages)) {
    //   const selected = lang == key;
    //   const langs = value.market ? value.market == currentMarket : false;
    //   const name = key.split("-", 1)[0];
    //   const url = (value.share as any).url;

    //   if (langs && value.share && url) {
    //     l.push({ key: name, url, selected });
    //   }
    // }

    return l;
  }

  get mx() {
    return ev_market.currentMarket == "mx";
  }

  get visible() {
    return ev_state.menuOpened;
  }

  changeLanguage(event: any) {
    event.preventDefault();
    const url: URL = new URL(event.target.dataset.value);
    let lang = url.pathname.replace("/", "");

    switch(lang) {
      case "ae-ar/":
        lang = "ar-ae"
        break;
      case "ae-en/":
        lang = "en-ae"
        break;
      case "en/":
        lang = "en-ca"
        break;
      case "fr/":
        lang = "fr-ca"
        break;
    }

    (this.$refs.select as HTMLElement).classList.toggle("open");

    if (event.target.dataset.current) {
      this.trackLang(event);
      return;
    }

    // REMOVE FOR PROD:
    //window.location.href = `https://${window.location.hostname}/${market.currentMarket}/${lang}`;

    window.location.href = event.target.dataset.value;
    //this.$i18n.locale = lang;
  }

  trackLang(event: any) {
    const url: URL = new URL(event.target.dataset.value);
    let trackLang = url.pathname.replace("/", "");

    switch(trackLang) {
      case "ae-ar/":
        trackLang = "english"
        break;
      case "ae-en/":
        trackLang = "arabic"
        break;
      case "en/":
        trackLang = "french"
        break;
      case "fr/":
        trackLang = "english"
        break;
    }

    TrackingEv.language(trackLang);
  }

  tagButton(alt: string) {
    TrackingEv.menuButton(alt);
  }
}
