





























































//import browserDetect from "browser-detect";
import { UspPanelData } from "@/store/models/UspPanelData";
import ev_state, { ViewType } from "@/store/modules/ev_state";
import Vue from "vue";
import Component from "vue-class-component";
import UIBtn from "../UIBtn/UIBtn.vue";
import EVCarrousel from "./EVCarrousel.vue";
import gsap from "gsap";
import Delay from "@/core/Delay";
import TrackingEv from "@/core/Tracking";
import ev_market from "@/store/modules/ev_market";
import { clamp } from '../../webgl/math';

@Component({
  components: {
    EVCarrousel,
    UIBtn,
  },
})

export default class EVUspPanel extends Vue {
  isOpen: boolean = false;
  urlUsp: string = "";
  scrollbarTL: gsap.core.Timeline;
  smallScrollbar: boolean = false;

  get panelData(): UspPanelData {
    return ev_state.currentUsp;
  }

  get panelDataFilled() {
    const p = this.panelData;
    if (p === null)
      return {
        title: "",
        text: "",
        legals: "",
        isEmpty: true,
        medias: [] as any[],
      };
    
    console.log(p)

    return p;
  }

  get learnMoreParam(): string {
    return "?evar25=ch_bolt-euv_ev-effect_" + this.panelData.id;
  }

  get meo() {
    return ev_market.currentMarket == "meo";
  }

  get rtl() {
    return ev_market.currentLocale == "ar-ae";
  }

  replaceURLs(message: string, id: string) {
    if(!message) return;
  
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url: string) {
      url = url.replace('"', '');
      let hyperlink = `${url}?evar25=ch_bolt-euv_ev-effect_${id}"`;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return hyperlink
    });
  }

  splitURL(message: string) {
    if(!message) return;
 
    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      url = url.replace('"', '');
      return `${url}"`;
    });
  }

  get textContent() {
    let id = "";
    if(this.panelData) id = this.panelData.id;
    const text = this.panelDataFilled.text;
    const parseText = this.replaceURLs(text, id);

    return parseText;
  }

  close() {
    ev_state.closeUsp();
  }

  mounted() {
    this.$watch("isOpen", async (open) => {
      if (open) {
        if (this.$refs.carrousel) {
          (this.$refs.carrousel as any).reset();
        }
        const scrollable = this.$el.getElementsByClassName(
          "scrollable"
        )[0] as HTMLElement;
        const scrollWrapper = this.$el.getElementsByClassName(
          "scroll-wrapper"
        )[0] as HTMLElement;

        //const browserInfo = browserDetect();
        await Delay(500);
        const scrollMax: number = scrollable.offsetHeight - scrollWrapper.offsetHeight;
        const scrollContainer: number = scrollWrapper.offsetHeight;
        const scrollBarHeight: number = scrollContainer - scrollMax;

        scrollBarHeight < 80 ? this.smallScrollbar = true : this.smallScrollbar = false;

        if(this.smallScrollbar) {
          this.scrollbarTL = gsap.timeline({paused: true});
          this.scrollbarTL.to(this.$refs.scrollVal, {y: scrollContainer, ease: "none"});
        } else {
          (this.$refs.scrollVal as HTMLElement).style.height = `${scrollBarHeight}px`;
        }
        
        if ((scrollWrapper.offsetHeight / scrollable.offsetHeight >= 1))
          (this.$refs.scrollVal as HTMLElement).style.visibility = "hidden";
        else (this.$refs.scrollVal as HTMLElement).style.visibility = "visible";

        this.$el
          .getElementsByClassName("scroll-wrapper")[0]
          .addEventListener("scroll", this.scroll);
        this.$el.classList.add("show");
      } else {
        if(this.smallScrollbar) this.scrollbarTL.kill();
      }
    });

    this.$watch("panelData", async (data) => {
      if (data !== null) {
        this.isOpen = true;
        document.body.classList.remove("disable-select");
      } else {
        this.$el
          .getElementsByClassName("scroll-wrapper")[0]
          .removeEventListener("scroll", this.scroll);
        this.$el.classList.remove("show");
        document.body.classList.add("disable-select");
        await Delay(500);
        this.isOpen = false;
      }
    });
  }

  beforeDestroy() {
    this.$el
      .getElementsByClassName("scroll-wrapper")[0]
      .removeEventListener("scroll", this.scroll);
  }

  scroll() {
    const s = this.$el.getElementsByClassName("scroll-wrapper")[0] as HTMLElement;
    const scrollable = this.$el.getElementsByClassName("scrollable")[0] as HTMLElement;
    const coef = (s.offsetHeight / scrollable.offsetHeight) + (70 / s.offsetHeight);
    let maxScroll = s.offsetHeight;
    let sT: number = s.scrollTop;
    if(this.smallScrollbar && sT > (maxScroll - 70)) sT = maxScroll - 70;
    let progress = Math.max(0, Math.min(1, Math.min(sT / maxScroll)));
    //progress *= coef;
    
    /* console.log("coef", coef);
    console.log("sT", sT);
    console.log("max", maxScroll);
    console.log(progress); */

    if(this.smallScrollbar) {
      this.scrollbarTL.progress(progress);
    } else {
      gsap.set(
        this.$refs.scrollVal,
        { y: `${sT}px` }
      );
    }
  }

  trackLink(text: string) {
    const url = this.splitURL(text);

    /* TrackingEv.uspLink(
      ev_state.viewType === ViewType.INSIDE,
      this.panelData.id,
      "chevrolet.co"
    ); */
  }

  tagLearnMore() {
    TrackingEv.textLink(
      ev_state.viewType === ViewType.INSIDE,
      this.panelData.id,
      this.panelData.link
    );
  }
}
