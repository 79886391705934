





































































import LottieAnimation from "../LottieAnimation/LottieAnimation.vue";
import ev_state, { ARInitState, ViewType } from "@/store/modules/ev_state";
import Vue from "vue";
import Component from "vue-class-component";
import { LocaleMessage, TranslateResult } from "vue-i18n";
import UIBtn from "../UIBtn/UIBtn.vue";
import Delay from "@/core/Delay";
import SwitchViewSVG from "!vue-svg-loader!@/assets/icons/switch-view.svg";
import Hand from "!vue-svg-loader!@/assets/icons/hand.svg";
import Tracking from "@/core/Tracking";
import ev_market from "@/store/modules/ev_market";

@Component({
  components: {
    UIBtn,
    LottieAnimation,
    SwitchViewSVG,
    Hand,
  },
})
export default class EVInOutSwitch extends Vue {
  discoverShow: boolean = false;

  get label(): TranslateResult {
    if (ev_state.viewType === ViewType.INSIDE) return this.$t("switch_outside");
    else return this.$t("switch_inside");
  }

  get rtl() {
    return ev_market.currentLocale == "ar-ae";
  }

  get wellDone(): LocaleMessage {
    return this.$t("hints.well_done");
  }

  get uspOpen(): boolean {
    return ev_state.currentUsp !== null;
  }

  get showDisclaimer(): boolean {
    return ev_market.currentMarket == "meo" || ev_market.currentMarket == "kr";
  }

  get disclaimer(): LocaleMessage {
    return this.$t("hints.plug_disclaimer");
  }

  async onSwitch() {
    ev_state.setIsLoading(true);
    await Delay(1000);

    if (ev_state.viewType === ViewType.INSIDE) {
      Tracking.discoverButton(false)
      ev_state.setViewType(ViewType.OUTSIDE);
      if (ev_state.exteriorLoaded) ev_state.setIsLoading(false);
    } else {
      Tracking.discoverButton(true)
      ev_state.setViewType(ViewType.INSIDE);
      if (ev_state.interiorLoaded) ev_state.setIsLoading(false);
    }
  }

  mounted() {
    ev_state.$watch(
      (s) => s.arInitState,
      (arInitState) => this.checkState(arInitState)
    );

    this.checkState(ev_state.arInitState);
  }

  checkState(arInitState: ARInitState) {
    if (arInitState === ARInitState.READY) this.startTimeLine();
    else if (arInitState === ARInitState.TAP_TO_PLACE) this.tapToPlace();
  }

  async tapToPlace() {
    await Delay(500);
    (this.$refs.bg as HTMLElement).classList.add("step-1");
    (this.$refs.place as HTMLElement).classList.add("show");
    if(this.showDisclaimer) (this.$refs.disclaimer as HTMLElement).classList.add("show");
  }

  async startTimeLine() {
    if (!this.$refs.bg) return;
    if (!ev_state.uIExpShown) {
      await Delay(500);
      (this.$refs.bg as HTMLElement).classList.add("step-1");
      (this.$refs.timeline as HTMLElement).classList.add("step-1");

      await Delay(2000);
      (this.$refs.timeline as HTMLElement).classList.add("step-2");

      await Delay(2000);
      (this.$refs.timeline as HTMLElement).classList.add("step-3");

      await Delay(2000);
      (this.$refs.timeline as HTMLElement).classList.add("step-4");
      ev_state.setuIExpShown(true);
    }
    // this.$el.getElementsByClassName("discover-btn")[0].classList.add("show");
    this.discoverShow = true;
    await Delay(200);
    (this.$refs.bg as HTMLElement).classList.add("step-2");
  }
}
