
const interiorHotspots = {
  super_cruise:{
    x:1858,
    y:1117
  },

  regen:{
    x:1716,
    y:1177
  },

  one_pedal:{
    x:1902,
    y:1454
  },

  connected:{
    x:2157,
    y:1232
  },

  health_screens:{
    x:2007,
    y:1196
  },

  onstar: {
    x:1970,
    y:875
  },

  performance: {
    x:1775,
    y:1177
  }
}

export default interiorHotspots