/* eslint-disable @typescript-eslint/no-explicit-any */
import IBL from './ibl'
import gui from '../dev/Gui'

import NGL_IBL from 'nanogl-pbr/lighting/Ibl'
import Input, { Uniform } from 'nanogl-pbr/Input'
import Enum from 'nanogl-pbr/Enum'
import Texture2D from 'nanogl/texture-2d'
import { GLContext } from 'nanogl/types'
import { GammaModes, GammaModeEnum } from 'nanogl-pbr/GammaModeEnum'
import { loadBytes, loadImage } from '@webgl/assets/Net'
import Scene from '@/webgl/Scene'
import IIblMaterialPass from '@webgl/glsl/IblMaterialPass'
import Paths from '@/core/Paths'






const INITIAL_IDX = 3;

const ENVS = [
  'Helipad',
  'helipad_v2',
  'helipad_v3',
  'helipad_v4',
  'uffizi',
  'parking_ext',
  'Mono_Lake_B',
  'MIT-01',
  'Ditch-River',
  'desert_highway',
  'Barce_Rooftop_C',
  '5008',
]


const EXPO = 1.4
const GAMMA = 2.2

class IBLManager {

    scene: Scene
    gl: GLContext

    envTex : Texture2D
    envHi  : Texture2D
    envBg  : Texture2D

    ibl: IBL

    EXPO: number = EXPO;
    GAMMA: number = 1 / GAMMA;

    gammaMode: GammaModeEnum

    gamma: Input
    exposure: Input
    expoUniform: Uniform
    gammaUniform: Uniform

    shMul: number
    shBase: Float32Array

    constructor(scene: Scene) {

        const gl = scene.gl;

        this.scene = scene;
        this.gl = gl;

        this.envTex = new Texture2D(this.gl, this.gl.RGBA);
        this.envHi = new Texture2D(this.gl, this.gl.RGBA);
        this.envBg = new Texture2D(this.gl, this.gl.RGB);

        this.ibl = new IBL(this.envTex, this.envHi, this.envBg);

        this.envTex.setFilter(false);


        this.shMul = 1.0;

        this.gammaMode = new Enum('gammaMode', GammaModes);
        this.gamma = new Input('gamma', 1, Input.ALL);
        this.exposure = new Input('exposure', 1, Input.ALL);

        this.gammaMode.set( 'GAMMA_STD' )
        this.expoUniform = this.exposure.attachUniform('utmExpo')
        this.gammaUniform = this.gamma.attachUniform('uTMGamma')
        this.expoUniform.set(EXPO);
        this.gammaUniform.set(1 / GAMMA);

        // this.loadDefault();

/////////////////////
///////////////////
//////////////////

    }

    setupLinear(){
        this.expoUniform.set( 1 );
        this.gammaUniform.set( 1 );
    }
    
    setupGamma(){
        this.expoUniform.set( EXPO );
        this.gammaUniform.set( 1/GAMMA );
    }

    setupMat(m: IIblMaterialPass) {


        // m.setLightSetup(this.lights.setup);

        m.iGamma?.proxy(this.gamma)
        m.iExposure?.proxy(this.exposure)
        m.gammaMode?.proxy(this.gammaMode)

    }

    setupMatTM(m: IIblMaterialPass) {
        m.iGamma?.proxy(this.gamma)
        m.iExposure?.proxy(this.exposure)
        m.gammaMode?.proxy(this.gammaMode)

    }

    dispose() {

        this.envTex?.dispose();
        this.envHi?.dispose();
        this.envBg?.dispose();

        this.envTex = null;
        this.envHi = null;
        this.envBg = null;

        this.ibl = null;
        this.scene = null;

        this.shBase = null;
        this.shMul = 1.0;

        console.log("dispose ibl !!!")

    }


    async loadDefault() : Promise<any> {
        return this.load('envs/' + ENVS[INITIAL_IDX])
    }


    async load(dir: string) : Promise<any> {

        return Promise.all([
          loadImage(Paths.resolve(`assets/webgl/${dir}/env.png`))
              .then((img) => this.envTex.fromImage(img)),
          // loadImage(Paths.resolve(`assets/webgl/${dir}/env_hi.png`))
          //     .then((img) => this.envHi.fromImage(img)),
          loadBytes(Paths.resolve(`assets/webgl/${dir}/sh.bin`))
              .then(this.convertSH)
        ]);

    }

    convertSH = (buf: ArrayBufferLike) => {
        this.shBase = new Float32Array(buf, 0, 9 * 3);
        this.ibl.sh = NGL_IBL.convert(this.shBase, this.shMul)
    }




    preRender() {
        // console.log(this.ibl);
    }


/////////////////
////////////

////////////////////
///////////////////////////////////////
///////////////////////////////////////

/////////////////////////////////////////////////
///////////////////////////////////////////////////////////
//////////////////////////////////
//////////

///////////////////
///////////////////////
///////////////////////
/////////////////////////
//////////////////////
/////////

///////////////////////////////////
///////////////////////////////////

/////////////////////////////////////////////////////
////////////////////////////////////////

/////////////////////////////
////////////////////
///////////////////////
///////////////////////
///////////////////////////////////

////////////////////////////
//////////////////////////////////////
///////////////////////////////////
/////////////////////////////
/////////////////////////////

///////////////////////////////////
/////////////////////////////////////////////////////////////////
//////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
/////////////////////////////////////////////
////////////////////

///////////////////////////////////
/////////////////////////////
/////////////////////////////
////////////////////////////////////////////////////
////////////////////////////////////////////////////////

/////////////

///////////



///////////////////////////////////////////////
//////////////////////////////////////////
////////////////////////////////
//////////

////////////////////////////////////////////////
/////////////////////////////////////////////////
///////////////////////////////////////
//////////

//////////////////////////////////////////////////
///////////////////////////////////
//////////////////////////////////////////////////////////////////
//////////


/////

//////////////

}

export default IBLManager;








// 'studio10',
// 'SD_studio',
// 'env_musee',
// 'default_env',
// 'uffizi',
// 'studio019',
// 'studio020',
// 'vanKleef',
// '5008',
// 'MonValley',
// 'doge2',
// 'Mans_Outside',
// 'pisa',
// 'hallstatt',
// 'parking_ext',
// 'Brooklyn_Bridge_Planks',
// 'Ditch-River',
// 'Tropical_Beach',
// '10-Shiodome_Stairs',
// 'Barce_Rooftop_C',
// 'Tokyo_BigSight',
// 'Factory_Catwalk',
// 'Charles-River-Esplanade-03',
// 'MIT-01',
// 'Mt-Washington-Hotel-Lookout',
// 'Tufts-Parking-Lot',
// 'studio016',
// 'studio017',
// 'studio018',
// 'studio021',
// 'studio022',
// 'studio023',
// 'studio024',
// 'abandonned',
// 'desert_highway',
// 'DS360_Free_001_v02_Ref',
// 'DS360_Free_002_Ref',
// 'village',
// 'Mono_Lake_B',
// 'Ridgecrest_Road',
// 'SD_abandoned_sanatorium_staircase',
// 'SD_airport',
// 'SD_bonifacio_aragon_stairs',
// 'SD_bonifacio_street',
// 'SD_bus_garage',
// 'SD_cave_entry_in_the_forest',
// 'SD_corsica_beach',
// 'SD_elevator_corridor',
// 'SD_Gdansk_shipyard_buildings',
// 'SD_glazed_patio',
// 'SD_industrial_room',
// 'SD_panorama_map',
// 'SD_road_in_tenerife_mountain',
// 'SD_small_apartment',
// 'SD_Soft_1Front',
// 'SD_Soft_1Front_2Backs',
// 'SD_Soft_1LowContrastFront_2Backs',
// 'SD_Soft_2RingHighContrast',
// 'SD_Soft_4TubeBank_2BlackFlags',
// 'SD_Soft_5DaylightStudio',
// 'SD_studio_03',
// 'SD_studio_04',
// 'SD_studio_05',
// 'SD_terrace_near_the_granaries',
// 'SD_tomoco_studio',
// 'SD_urban_exploring_interior',