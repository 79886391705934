import { render, staticRenderFns } from "./EVHotspot.vue?vue&type=template&id=37972f50&scoped=true&"
import script from "./EVHotspot.vue?vue&type=script&lang=ts&"
export * from "./EVHotspot.vue?vue&type=script&lang=ts&"
import style0 from "./EVHotspot.vue?vue&type=style&index=0&id=37972f50&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37972f50",
  null
  
)

export default component.exports