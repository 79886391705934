

type Detail = {
  'data-dtm':string
  alt? :string
}


export default class TrackingEv {

  
  static track( identifier:string, detail?:Detail ){
    window._satellite?.track( identifier, detail )
  }
  
  static trackData( identifier:string, dtm: string, alt?:string ){
    const detail: Detail = {
      'data-dtm' : dtm,
      alt
    }
    console.log('track', identifier, dtm, alt)
    window._satellite?.track( identifier, detail )
  }


  static holdPlug() {
    this.trackData( 'hold-plug', 'intro' )
  }

  static trackErrorModal() {
    this.trackData('error-modal', 'error-type', 'fps-error')
  }

  static trackErrorModalBtn( btn: string ) {
    this.trackData('error-modal', 'error-type', btn)
  }

  // Color selection expand
  static openColors( interior:boolean ){
    const dtm = interior?'interior':'exterior'
    this.trackData( 'image-link', `${dtm}:colors`, 'expand' )
  }
  
  // Color selection collapse
  static closeColors( interior:boolean ){
    const dtm = interior?'interior':'exterior'
    this.trackData( 'image-link', `${dtm}:colors`, 'collapse' )
  }

  // Color chips
  static selectColor( interior:boolean, colorName : string ){
    const dtm = interior?'interior':'exterior'
    let trackColor;

    if(interior) {
      switch(colorName) {
        case "black":
          trackColor = "Jet Black with Nightshift Blue"
          break;
        case "grey":
          trackColor = "Sky Cool Grey Leather"
          break;
        case "blue":
          trackColor = "Jet Black with Captain Blue Leather"
          break;
        case "dark":
          trackColor = "Bright Blue Metallic"
          break;
        case "white":
          trackColor = "Silver Flare Metallic"
          break;
      }
    } else {
      switch(colorName) {
        case "white":
          trackColor = "Silver Flare Metallic"
          break;
        case "red":
          trackColor = "Cherry Red Tintcoat"
          break;
        case "blue":
          trackColor = "Bright Blue Metallic"
          break;
        case "grey":
          trackColor = "Gray Ghost Metallic"
          break;
      }
    }
    
    if(typeof trackColor !== undefined)
      this.trackData( 'image-link', `${dtm}:colors`, trackColor )
  }

  // Share icon
  static shareIcon(){
    this.trackData( 'icon-link', 'global nav', 'share' )
  }

  // Exterior/Interior Hotspots
  static openUsp( interior:boolean, name:string ){
    const dtm = interior?'interior':'exterior'
    this.trackData( 'hotspot-link', `${dtm}:hotspot`, name )
  }

  // Discover button
  static discoverButton( interior:boolean ){
    const dtm = interior?'exterior':'interior'
    const alt = interior?'inside':'outside'
    this.trackData( 'button-link', dtm, 'discover ' + alt )
  }

  static uspNext( interior:boolean, name:string ){
    const dtm = interior?'interior':'exterior'
    this.trackData( 'arrow-next', `${dtm}:modal:${name}` )
  }

  static uspPrev( interior:boolean, name:string ){
    const dtm = interior?'interior':'exterior'
    this.trackData( 'arrow-prev', `${dtm}:modal:${name}` )
  }

  static uspLearnMore( interior:boolean, name:string ){
    const dtm = interior?'interior':'exterior'
    this.trackData( 'button-link', `${dtm}:modal:${name}`, 'learn more' )
  }

  static uspLink( interior:boolean, name:string, alt:string ){
    const dtm = interior?'interior':'exterior';
    this.trackData( 'button-link', `${dtm}:modal:${name}`, alt );
  }

  static textLink( interior:boolean, name:string, alt:string ){
    const dtm = interior?'interior':'exterior';
    this.trackData( 'text-link', `${dtm}:modal:${name}`, alt );
  }


  // Cookies banner
  static closeCookies() {
    this.trackData( 'button-close' , 'cookie banner', 'close' )
  }

  static openPrivacyLink() {
    this.trackData( 'text-link' , 'cookie banner', 'https://www.gm.ca/en/home/privacy-statement.html' )
  }


  // Menu
  static openMenu(){
    this.trackData( 'icon-link' , 'global nav', 'more info' )
  }

  static menuPlayVideo(){
    this.trackData( 'icon-link' , `global nav`, 'play video' )
  }

  static menuButton(alt:string){
    this.trackData( 'button-link' , `global nav`, alt )
  }

  static language(lang: string) {
    switch(lang) {
      case "en":
        lang = "english";
        break;
      case "fr":
        lang = "french"
        break;
      case "ar":
        lang = "arabic"
        break;
    }

    this.trackData( 'text-link' , `global nav`, lang )
  }


}