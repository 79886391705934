/* eslint-disable @typescript-eslint/no-explicit-any */

import fps from "@/store/modules/fps";
import GLView from "@webgl/GLView";
import { mat4 } from "gl-matrix";
import Fbo from "nanogl/fbo";
import Texture2D from "nanogl/texture-2d";
import Time, { FpsSampler } from "../Time";
import AR8thLoader from "./AR8thLoader";
// optional
// import AR8thViewLayout from './AR8thViewLayout';


export default class AR8thView {

  static cameraMatrix = mat4.create()


  glview: GLView;
  started: boolean;
  rendering: boolean;
  loaded: boolean;
  isRecording: boolean;

  cameraTexture: Texture2D

  fbo: Fbo;

  fpsSampler : FpsSampler

  private _loadAndStartPromise: Promise<void>;

  constructor(glview: GLView) {
    this.glview = glview;

    this.loaded = this.rendering = false

    const gl  = glview.gl
    this.cameraTexture = new Texture2D(glview.gl, glview.gl.RGB, glview.gl.UNSIGNED_BYTE)
    this.fbo = new Fbo(gl)
    this.fbo.attachColor( gl.RGB )
    this.fbo.getColorTexture().clamp()

    this.fpsSampler = new FpsSampler( 10 )
  }

  async loadAndStart() : Promise<void>{
    if( !this._loadAndStartPromise ){
      this._loadAndStartPromise = this._loadAndStart()
    }
    return this._loadAndStartPromise
  }

  async _loadAndStart() : Promise<void>{
    await AR8thLoader.awaitLibrary()
    this.start()
    this.loaded = true
    this.rendering = true
  }

  start() {

    const XR8 = window.XR8;

/////////////////
//////////////////////////////////////////////////////
////////////
/////
//////////////

    XR8.addCameraPipelineModules([
      // Handle XR events and AR scene controls
      XR8.XrController.pipelineModule(),
      // Handle custom messages

      window.XRExtras.AlmostThere.pipelineModule(),
      window.XRExtras.Loading.pipelineModule(),

      //Launch main module using our own render function
      {
        name: 'require_mic',
        requiredPermissions: () => ([XR8.XrPermissions.permissions().MICROPHONE])
      },

      //Launch main module using our own render function
      {
        name: 're-gltexture',
        onRender: this.onPregltextureRender
      },

      // Will be used to render camera stream
      XR8.GlTextureRenderer.pipelineModule(),

      //Launch main module using our own render function
      {
        name: 'main-render',
        onStart: this.onARStart,
        onUpdate: this.onARUpdate,
        onRender: this.onRender
      },

    ]);

    // XR Config
    XR8.XrController.configure({
      enableWorldPoints: false,
    });


    XR8.GlTextureRenderer.configure({
      // fragmentSource: cameraShader,
      // toTexture: this.fbo.getColorTexture()
      // toTexture: this.cameraTexture
    })

    //Launch 8th wall on any devices

    XR8.run({
      canvas: this.glview.canvas,
      allowedDevices: XR8.XrConfig.device().ANY,
    });

  }

  stop() {
    const XR8 = window.XR8
    XR8.stop()
    XR8.clearCameraPipelineModules()

    this.rendering = false
  }



  onARStart = () => {

    this.glview.canvasWidth = window.innerWidth
    this.glview.canvasHeight = window.innerHeight

    this.glview.updateSize();


    window.XR8.XrController.updateCameraProjectionMatrix({
      cam: {
        pixelRectWidth: this.glview.width,
        pixelRectHeight: this.glview.height,
        nearClipPlane: 0.1,
        farClipPlane: 200
      },
      // origin: { x: p[0], y: 1.5, z: p[2] },
      // facing: { x: r[0], y: r[1], z: r[2], w: r[3] }
    });

    this.glview.canvas.style.removeProperty('position');
    this.glview.canvas.style.removeProperty('left');
    this.glview.canvas.style.removeProperty('top');
    this.glview.canvas.style.removeProperty('z-index');

    // !!! XR8 Add some styles to this parent node, we remove it
    // (document.body.getElementsByClassName(
    //     'app-container'
    // )[0] as HTMLElement).style.position = '';

    this.started = true;

    window.XR8.XrController.recenter();

  }


  onARUpdate = (args: any) => {

    this.glview._checkSize();

    // Get the scene camera
    const camera = this.glview.scene.mainCamera;

    window.XR8.XrController.updateCameraProjectionMatrix({
      cam: {
        pixelRectWidth: this.glview.width,
        pixelRectHeight: this.glview.height,
        nearClipPlane: .01,
        farClipPlane: 50
      }
    });

    if (args.processCpuResult.reality) {

      // Update camera and projection
      const { position, rotation, intrinsics } = args.processCpuResult.reality;
      camera.position[0] = position.x;
      camera.position[1] = position.y;
      camera.position[2] = position.z;
      camera.rotation[0] = rotation.x;
      camera.rotation[1] = rotation.y;
      camera.rotation[2] = rotation.z;
      camera.rotation[3] = rotation.w;

      for (let i = 0; i < 16; i++) {
        camera.lens._proj[i] = intrinsics[i];
      }

      camera.lens._valid = true;
      camera.invalidate()

      camera.updateWorldMatrix()
      
      AR8thView.cameraMatrix.set( camera._wmatrix)

    }

  };

  onPregltextureRender = () => {
    
    this.fbo.bind()
    this.fbo.resize(this.glview.width, this.glview.height)
    this.fbo.defaultViewport()
    
    // debugger
  }



  onRender = () => {
    if (!this.started || !this.rendering)
    return;
    
    this.fpsSampler.sampleFrame()

    const fpsVal = Math.round(this.fpsSampler.fps)
    fps.setValue(fpsVal)

    this.glview.render(Time.dt);

  }

}
