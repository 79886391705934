













































import Delay from "@/core/Delay";
import Tracking from "@/core/Tracking";
import ev_state from "@/store/modules/ev_state";
import Vue from "vue";
import Component from "vue-class-component";
import EVBanner from "../EVBanner/EVBanner.vue";
import EVNavBtn from "../EVNavBtn/EVNavBtn.vue";

@Component({
  components: {
    EVBanner,
    EVNavBtn,
  },
})
export default class EVNavBar extends Vue {
  showInit = false;
  showInitShare = false;

  get mainBtnIcon(): string {
    if (ev_state.currentUsp !== null) return "close";

    if (ev_state.menuOpened) return "close";

    return "burger";
  }

  get showBanner() {
    return ev_state.currentUsp === null;
  }

  get shareAvailable() {
    return navigator.share !== undefined;
  }

  get showShare() {
    return ev_state.currentUsp === null && this.shareAvailable;
  }

  get showPrevNext() {
    return ev_state.currentUsp !== null;
  }

  onClickMain() {
    if (ev_state.currentUsp !== null) {
      ev_state.closeUsp();
    } else {
      ev_state.setMenuOpened(!ev_state.menuOpened);
    }
  }

  onShare() {
    const title: any = this.$t(`share.title`);
    const text: any = this.$t(`share.text`);
    const url: any = this.$t(`share.url`);

    if (navigator.share) {
      navigator.share({ url, title, text });
    }

    Tracking.shareIcon();
  }

  mounted() {
    this.initTrans();
  }

  async initTrans() {
    this.showInit = this.showInitShare = false;
    await Delay(200);
    this.showInit = true;
    await Delay(200);
    this.showInitShare = true;
  }
}
