




















import Delay from "@/core/Delay";
import ev_state from "@/store/modules/ev_state";
import ev_market from "@/store/modules/ev_market";
import Vue from "vue";
import Component from "vue-class-component";
import { LocaleMessage } from "vue-i18n";
import { Prop } from "vue-property-decorator";
import LottieAnimation from "../LottieAnimation/LottieAnimation.vue";

@Component({
  components: {
    LottieAnimation,
  },
})
export default class LoadingPage extends Vue {
  @Prop({ type: Boolean, default: false }) active: boolean;
  blockLoading = false;
  mounted() {
    ev_state.$watch(
      (s) => s.isLoading,
      async (isLoading) => {
        if (isLoading && ev_state.introDone) {
          (this.$refs.whitewaves as LottieAnimation).play();
          this.$el.classList.add("is-loading");
          this.blockLoading = true;
          await Delay(2000);
          this.blockLoading = false;
          if (!ev_state.isLoading) this.out();
        } else {
          if (!this.blockLoading) this.out();
        }
      }
    );
  }

  get loading(): LocaleMessage {
    return this.$t("loading");
  }

  async out() {
    this.$el.classList.remove("is-loading");
    await Delay(1000);
    (this.$refs.whitewaves as LottieAnimation).stop();
  }
}
