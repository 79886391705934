
































import Paths from "@/core/Paths";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import gsap from "gsap";

import ChevronLeft from "!vue-svg-loader!@/assets/icons/chevron-left.svg";
import ChevronRight from "!vue-svg-loader!@/assets/icons/chevron-right.svg";
import { Expo } from "gsap/all";
import Delay from "@/core/Delay";
import Tracking from "@/core/Tracking";
import ev_state, { ViewType } from "@/store/modules/ev_state";

@Component({
  components: {
    ChevronRight,
    ChevronLeft,
  },
})
export default class EVCarrousel extends Vue {
  @Prop({ type: Array, required: false }) medias!: string[];
  @Prop({ type: String, required: false }) uspid!: string;

  currPic: number = 0;
  prevPos: number = 0;

  mounted() {
    this.currPic = 0;
    this.prevPos = 0;
  }

  async reset() {
    this.currPic = 0;
    this.prevPos = 0;
    const s = this.$el.getElementsByClassName("scrollable")[0];
    gsap.set(s, { x: 0 });

    this.$el.classList.remove("show");
    await Delay(1000);
    this.$el.classList.add("show");
  }

  resolveSrc(p: string) {
    return Paths.resolve(p);
  }

  goLeft() {
    this.currPic = Math.max(0, this.currPic - 1);
    this.gotoCurrent();
    Tracking.uspPrev( ev_state.viewType === ViewType.INSIDE, this.uspid )
  }

  goRight() {
    this.currPic = Math.min(this.medias.length - 1, this.currPic + 1);
    this.gotoCurrent();
    Tracking.uspNext( ev_state.viewType === ViewType.INSIDE, this.uspid )
  }

  gotoCurrent() {
    const els = this.$el.getElementsByClassName("item");
    const rect = els[this.currPic].getBoundingClientRect();
    const s = this.$el.getElementsByClassName("scrollable")[0];
    const rectS = s.getBoundingClientRect();
    const rectW = this.$el
      .getElementsByClassName("scroll-wrapper")[0]
      .getBoundingClientRect();
    const prevX = this.getTranslateXY(s as HTMLElement).translateX;
    gsap.to(s, {
      duration: 1,
      x: Math.max(-(rectS.width - rectW.width), prevX - (rect.left - 9)),
      ease: Expo.easeInOut,
    });
    this.prevPos = -rect.left;
    // Array.from(els).forEach((img) => {
    //   gsap.to(img.getElementsByTagName("img"), {
    //     duration: 1,
    //     x: 15 - (this.currPic / (this.medias.length - 1)) * 30,
    //     ease: Expo.easeInOut,
    //   });
    // });
  }

  getTranslateXY(element: HTMLElement) {
    const style = window.getComputedStyle(element);
    const matrix = new DOMMatrixReadOnly(style.transform);
    return {
      translateX: matrix.m41,
      translateY: matrix.m42,
    };
  }
}
