

import GLView from './GLView'
import Scene from './Scene'

// import {gui} from './dev'
// console.log( gui );


// TODO: move this somwhere else
import { TextureCodecs } from './assets/TextureCodec'
import TextureCodecStd from './assets/TextureCodecStd'


import AR8thView from './ar/AR8thView'
import AR8thLoader from './ar/AR8thLoader'


TextureCodecs.registerCodec(new TextureCodecStd());



import Gltf from './lib/nanogl-gltf/lib'
import KHR_texture_transform from './lib/nanogl-gltf/lib/extensions/KHR_texture_transform'
import KHR_draco_mesh_compression from './lib/nanogl-gltf/lib/extensions/KHR_draco_mesh_compression'
import KHR_materials_pbrSpecularGlossiness from './lib/nanogl-gltf/lib/extensions/KHR_materials_pbrSpecularGlossiness'
import KHR_lights_punctual from './lib/nanogl-gltf/lib/extensions/KHR_lights_punctual'
import KHR_materials_unlit from './lib/nanogl-gltf/lib/extensions/KHR_materials_unlit'
import EXT_texture_webp from './lib/nanogl-gltf/lib/extensions/EXT_texture_webp'
import MMP_compressed_texture from './lib/nanogl-gltf/lib/extensions/MMP_compressed_texture'
import IModule from './IModule'


Gltf.addExtension(new KHR_texture_transform());
Gltf.addExtension(new KHR_draco_mesh_compression());
Gltf.addExtension(new KHR_materials_pbrSpecularGlossiness());
Gltf.addExtension(new KHR_lights_punctual());
Gltf.addExtension(new KHR_materials_unlit());
Gltf.addExtension(new EXT_texture_webp());
Gltf.addExtension(new MMP_compressed_texture());




/**
  @opts :

    REQUIRED:
    'canvas'    {HTMLCanvasElement}   :  context free canvas element in which scene will be rendered
    'subtitles' {HTMLElement}         :  a dom element in which subtitles will be innerHTML-ed
    'config'    {string}              :  url to the xml config file 
    'assetsUrl' {string}              :  3D assets base url without trailing slash
    'model'     {Model}               :  Model instance shared with the page

    OPTIONAL:
    'ilayer'    {HTMLElement}         :  dom elemnt on which touch/mouse event are listened, default to canvas element 
    'quality'   {string}              :  enum 'auto', 'hi' or 'low'

**/
export default class App {


  glview: GLView
  scene: Scene
  arview: AR8thView

  constructor(canvas:HTMLCanvasElement, module : IModule ) {

    this.glview = new GLView(canvas);
    this.scene  = new Scene();
    this.arview = new AR8thView(this.glview)

    this.scene.ilayer = canvas.parentElement;

    this.glview.scene = this.scene;
    this.scene.init(this.glview, this.arview, module);

  }

  /**
    Start loading assets
    @return a (when.js) promise 
  **/
  async load() {
    await this.scene.load();
  }

  async loadAR() {
    this.arview.loadAndStart()

/////////////////
//////////////////////////////////////////////////////
/////////////////////////
/////
//////////////
  }

  stop() {
    this.arview.stop()
  }



}



/////////////

/////////////////
///////////////////////
 

//////////