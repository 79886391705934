
const baseDir = __webpack_public_path__

export default {
  
  resolve(path:string):string{
    // console.log(baseDir+path);
    return baseDir+path;
  },

}