import { quat } from "gl-matrix";
import { mat4 } from "gl-matrix";
import { vec2, vec3, vec4 } from "gl-matrix";
import Camera from "nanogl-camera";
import Node from "nanogl-node";
import DebugDraw from "../dev/DebugDraw";
import { InputTouch } from "../lib/inputs";
import Plane from "../math/Plane";
import Ray from "../math/Ray";
import rayPlaneIntersection from "../math/ray-plane-intersection";
import rayPointDist from "../math/ray-point-dist";
import Scene from "../Scene";

const ray = new Ray()
const groundPlane = new Plane()

const V2 = vec2.create()
const V3A = vec3.create()
const M4A = mat4.create()
const Q_ID = quat.create()

const MAX_DIST = 10

enum InteractionState {
  IDLE,
  MOVE,
  SCALE
}

export default class ARController {

  t0: InputTouch
  t1: InputTouch

  mode = InteractionState.IDLE

  scale = 1
  rotation = 0

  isPlaced = false

  baseHitSphereRadius = 3

  _started = false

  constructor(private scene: Scene, private node: Node, public allowRotate = true) {
  }


  start(){
    if( this._started ) return
    this._started = true
    
    this.scene.inputs.onTouchAdded.on(this.touchesChanged)
    this.scene.inputs.onTouchRemoved.on(this.touchesChanged)
  }
  
  stop(){
    if( !this._started )return
    this._started = false
    
    this.scene.inputs.onTouchAdded.off(this.touchesChanged)
    this.scene.inputs.onTouchRemoved.off(this.touchesChanged)
    
    this.setMode( InteractionState.IDLE )
  }
  
  get camera() : Camera {
    return this.scene.camera
  }

  
  setMode(m: InteractionState) {
    if (this.mode === m) return
    switch (this.mode) {
      case InteractionState.IDLE: this.leaveIdle(); break;
      case InteractionState.MOVE: this.leaveMove(); break;
      case InteractionState.SCALE: this.leaveScale(); break;
    }
    this.mode = m
    switch (this.mode) {
      case InteractionState.IDLE: this.enterIdle(); break;
      case InteractionState.MOVE: this.enterMove(); break;
      case InteractionState.SCALE: this.enterScale(); break;
    }
  }
  
  touchesChanged = () => {
    this.setMode(this.getCurrentInteractionMode())
  }

  getCurrentInteractionMode() {
    const numPointers = this.scene.inputs.touches.length

    if (numPointers > 0 && this.isPlaced && this.isAnyPointerOnFaceMesh()) {
      if (numPointers === 1) {
        return InteractionState.MOVE;
      } else {
        return InteractionState.SCALE;
      }
    } else {
      if (numPointers === 1) {
        return InteractionState.MOVE;
      }
    }

    return InteractionState.IDLE;

  }


  update() {
    switch (this.mode) {
      case InteractionState.IDLE: this.updateIdle(); break;
      case InteractionState.MOVE: this.updateMove(); break;
      case InteractionState.SCALE: this.updateScale(); break;
    }
  }

  // ================================== IDLE

  enterIdle() {
    0
  }

  updateIdle() {
    0
  }

  leaveIdle() {
    0
  }


  // ================================== MOVE


  touchMoveSPOffset = vec2.create()

  updateTouchOffset( coord: vec2 ){
    if( this.isPlaced && this.isAnyPointerOnFaceMesh() ){
      vec3.transformMat4(V3A, this.node._wposition as vec3, this.camera._viewProj )
      vec2.sub( this.touchMoveSPOffset, V3A as unknown as vec2, coord)
    } else {
      this.touchMoveSPOffset[0] = 
      this.touchMoveSPOffset[1] = 0
    }
  }

  enterMove() {
    this.updateTouchOffset(this.scene.inputs.touches[0].coords)
  }
  
  updateMove() {
    this.getMedianTouchCoords(V2)
    vec2.add( V2, V2, this.touchMoveSPOffset)
    this.solveMoveOnScrenPoint( V2 );
  }
  
  
  leaveMove() {
    0
  }

  // ================================== SCALE ROTATE



  private _intitialPinchDist    = 0;
  private _intitialPinchAngle   = 0;
  private _intitialUserRotation = 0;
  private _intitialUserScale    = 0;

  enterScale() {

    this.getMedianTouchCoords(V2)
    this.updateTouchOffset(V2)

    this._intitialPinchDist = this.getPinchDist();
    this._intitialPinchAngle = this.getPinchAngle();
    this._intitialUserRotation = this.rotation;
    this._intitialUserScale    = this.scale;
  }

  updateScale() {
 
    this.getMedianTouchCoords(V2)
    vec2.add( V2, V2, this.touchMoveSPOffset)
    this.solveMoveOnScrenPoint( V2 );


    const deltaAngle = this.getPinchAngle() - this._intitialPinchAngle;
    const scale = this.getPinchDist() / this._intitialPinchDist;

    this.rotation = this._intitialUserRotation - deltaAngle;
    this.scale = this._intitialUserScale * scale;

    this.setNodeRotationScale( this.rotation, this.scale )
  }

  leaveScale() {
0
  }




  /**
  * return distance between 2 first touches in screen space
  */
   private getPinchDist( ): number{
     const touches = this.scene.inputs.touches
    if( touches.length < 2 ){
      return 0;
    } else {
      return vec2.distance( touches[0].coords, touches[1].coords)
    }
  }

  /**
  * return distance between 2 first touches in screen space
  */
  private getPinchAngle( ): number{
    const touches = this.scene.inputs.touches
   if( touches.length < 2 ){
      return 0;
    } else {
      vec2.sub( V2, touches[0].coords, touches[1].coords )
      return Math.atan2( V2[1], V2[0] );
    }
  }



  isAnyPointerOnFaceMesh(): boolean {

    const cam = this.camera
    const touches = this.scene.inputs.touches
    const radius = this.baseHitSphereRadius * this.node.scale[0]

    for (let i = 0; i < touches.length; i++) {
      const t = touches[i];
      ray.unproject(t.coords, cam)
      const d = rayPointDist(ray, this.node._wposition as vec3)
      
      if (d < radius) return true
    }

    this.getMedianTouchCoords(V2)

    ray.unproject(V2, cam)
    const d = rayPointDist(ray, this.node._wposition as vec3)
    if (d < radius) return true

    return false

  }


  solveMoveOnScrenPoint(sceenPoint: vec2) {
    // offset touch hit with offset stored in enter state
    ray.unproject(sceenPoint, this.camera)

    if( this.findPlacementForRay( ray, V3A )){
      // DebugDraw.drawGuizmo(V3A)
      this.isPlaced = true
      this.setNodeWorldPosition( V3A )
    }
  }

  setNodeWorldPosition(p: vec3) {
    mat4.invert( M4A, this.node._parent._wmatrix )
    vec3.transformMat4( this.node.position, p, M4A )
    
    this.node.invalidate()
  }

  setNodeRotationScale(r:number, s:number) {
    if( this.allowRotate ){
      quat.rotateY( this.node.rotation, Q_ID, -r )
    }
    this.node.setScale(s)
    this.node.invalidate()
  }

  findPlacementForRay( ray:Ray, out: vec3 ): boolean {

    if( ray.dir[1] > -0.01 ) ray.dir[1] = -0.01
    
    const hit = rayPlaneIntersection( out, ray, groundPlane )

    if( hit ){
      // limit hit distance
      const camp = this.camera._wposition
      V2[0] = out[0] - camp[0]
      V2[1] = out[2] - camp[2]
      if( vec2.length(V2) > MAX_DIST ){
        vec2.normalize(V2, V2)
        out[0] = camp[0] + V2[0]*MAX_DIST
        out[2] = camp[2] + V2[1]*MAX_DIST
      }
    }

    return hit
  }


  getMedianTouchCoords(out: vec2): void {

    const touches = this.scene.inputs.touches

    if (touches.length == 0) {
      0
    } else if (touches.length == 1) {
      out.set(touches[0].coords)
    } else {
      vec2.add(out, touches[0].coords, touches[1].coords)
      vec2.scale(out, out, .5)
    }
  }




}