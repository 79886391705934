
import WebGltfIO from "@webgl/lib/nanogl-gltf/lib/io/web";
import Paths from "@/core/Paths";
import UnlitMaterial from "@webgl/lib/nanogl-gltf/lib/extensions/KHR_materials_unlit/UnlitMaterial";
import GltfTypes from "@webgl/lib/nanogl-gltf/lib/types/GltfTypes";
import GltfModule from "../GltfModule";
import MaterialOverrideExtension from "../../lib/nanogl-gltf/lib/extensions/MaterialOverrideExtension";
import BaseMaterial from "nanogl-pbr/BaseMaterial";
import { ClearcoatSpecular } from "../../glsl/clearcoat/ClearcoatPass";
import { Passes } from "../../glsl/Passes";
import IIblMaterialPass from "../../glsl/IblMaterialPass";
import { TmpHexToVec } from "../../lib/color";
import gui, { GUI_vec3Color } from "../../dev/Gui";
import vec3 from 'gl-matrix/src/gl-matrix/vec3'
import Material from "../../lib/nanogl-gltf/lib/elements/Material";
import { StandardPass } from "nanogl-pbr/StandardPass";
import Masks from "../../gl/Masks";
import TexCoord from "nanogl-pbr/TexCoord";
import { Uniform } from "nanogl-pbr/Input";
import EVModule from "../../EVModule";
import ev_state, { ARInitState } from "@/store/modules/ev_state";
import { BodyColor, BodyColorConfigs, BodyColors } from "@/store/models/BodyColors";
import Flash from "./Flash";
import { safari } from '@/core/Browser';
import { remapProgress } from "@/webgl/math";
import RevealEffect from "./RevealEffect";
import gsap from "gsap";
import { easeInCubic, linear } from "@/webgl/math/ease";
import CircleLines, { CircleConfig } from "./CircleLines";
import GetModel from "@/webgl/GetModel";

const LINES : CircleConfig[] = []

for (let i = 0; i < 10; i++) {
  LINES[i] = {
    radius : 1.0 + i*.01, 
    thickness : .15 + .15*(1-(i%3)),
    height: 0
  }
}


export default class CarScene extends GltfModule {


  materialOverride: MaterialOverrideExtension;
  passes: IIblMaterialPass[]
  carpaint: BaseMaterial;

  paintDiffuse : Uniform
  paintSpecular: Uniform
  paintGloss   : Uniform
  flash        : Flash



  introProgress = 0

  revealEffect : RevealEffect
  circles: CircleLines;
  
  constructor(private module: EVModule) {
    super(module.scene, Paths.resolve(`assets/webgl/gltf/Model${GetModel()}.gltf`), module.iblMngr)
    this.passes = []
    
    this.carpaint = this.createCarPaint();
    this.materialOverride = new MaterialOverrideExtension()
    this.materialOverride.overrides = {
      carpaint: this.carpaint
    }
    
    
    this.flash = new Flash( module.scene )
    this.revealEffect = new RevealEffect(module.scene)

    this.circles = new CircleLines(module.scene)
    this.circles.node.scale.set([1.8, 1, 3.3])
    this.circles.speed = 60
    this.circles.separation = .3


/////////////////

////////////////////////////////////////////
////////////////////////////////////////////////////////////////
//////////////////////////////
//////

//////////////////////////////


//////////////
  }

  get inReveal():boolean {
    return this.revealEffect.progress < 1
  }



  async load(): Promise<void> {

    this.gltf = await WebGltfIO.loadGltf(this.path, {
      extensions: [this.materialOverride]
    });

    await this.gltf.allocateGl(this.scene.gl);
    await this.circles.load()
    this.computeBounds()
    this.setupMaterials()
    this.onLoaded()
  }

  playIntro(){
    window.navigator.vibrate?.(300);
    gsap.killTweensOf(this, "introProgress")
    this.introProgress = 0
    gsap.to(this, {introProgress: 1, duration: safari ? 2 : 3, ease: linear, onComplete:()=>this.introAnimComplete() } )
  }

  introAnimComplete(){
    ev_state.setARInitState(ARInitState.READY)
  }

  
  preRender(){
    this.revealEffect.progress = remapProgress( this.introProgress, .1, 1 )
    
    const flash = 1.0 - remapProgress( this.introProgress, 0, .7 )
    this.flash.strength = Math.pow(flash, 2)
    
    this.setupColor()
    super.preRender()
  }
  
  rttRender(){
    super.rttRender()
    if( this.inReveal ) {
      this.revealEffect.rttPass(this)
    }
  }


  renderCarOnly(){
    super.render()
  }
  
  render(){
    if( ! this.inReveal ){
      super.render()
      this.renderCircles()
    } else {
      this.revealEffect.blit()
    }

    this.flash.render()


  }
  
  renderCircles(){
    let p = 1.0-this.introProgress
    p = easeInCubic(p) 
    const configs = LINES.map( (l,i):CircleConfig =>{
      return {
        radius: l.radius,
        thickness: l.thickness,
        height: .1 + .2 * i * p
      }
    })
    this.circles.render(configs)
  }


  setupColor(){
    const cfg = BodyColorConfigs[ev_state.bodyColor]
    this.paintDiffuse._value.set(TmpHexToVec( cfg.diff))
    this.paintSpecular._value.set(TmpHexToVec( cfg.spec))
    this.paintGloss.set(cfg.gloss)
  }


  onLoaded(): void {


    this.gltf.root.add( this.circles.node)


    const gl = this.scene.gl
    const groundOcclu = this.gltf.getElementByName(GltfTypes.MATERIAL, "GroundOcclu") as UnlitMaterial
    groundOcclu.materialPass.glconfig
      .enableBlend()
      .blendFunc(gl.ZERO, gl.SRC_COLOR)



    for (const material of this.gltf.materials) {
      //console.log(material.name, (material as unknown as Material).materialPass?.mask);
    }

    for (const pass of this.passes) {
      pass.setLightSetup(this.lightSetup)
      this.module.iblMngr.setupMat(pass);
    }

    const occluTexture = this.gltf.getElementByName(GltfTypes.TEXTURE, 'BodyPartsVRayCompleteMap')
    const ccpass = this.carpaint.getPass( Passes.COLOR ).pass as ClearcoatSpecular
    ccpass.occlusion.attachSampler('socclus', TexCoord.create()).set( occluTexture.glTexture )

    // glasses

    let m = this.gltf.getElementByName(GltfTypes.MATERIAL, 'windowglass') as unknown as Material

    let pass = m.materialPass as StandardPass
    pass.glconfig
      .enableBlend()
      .blendColor(.2, .2, .2, 1.0)
      .blendFunc(gl.ONE, gl.CONSTANT_COLOR)
    pass.surface.baseColor.attachConstant([0, 0, 0])
    pass.surface.baseColorFactor.attachConstant([0, 0, 0])

    m = this.gltf.getElementByName(GltfTypes.MATERIAL, 'darkglass') as unknown as Material
    if( m ){
      pass = m.materialPass as StandardPass
      pass.glconfig
      .enableBlend()
      .blendColor(.15, .15, .15, 1.0)
      .blendFunc(gl.ONE, gl.CONSTANT_COLOR)
      pass.surface.baseColor.attachConstant([0, 0, 0])
      pass.surface.baseColorFactor.attachConstant([0, 0, 0])
    }
      
    m = this.gltf.getElementByName(GltfTypes.MATERIAL, 'clearglass') as unknown as Material
    pass = m.materialPass as StandardPass
    pass.mask = Masks.BLENDED2
    pass.glconfig
      .enableBlend()
      .blendColor(.95, .95, .95, 1.0)
      .blendFunc(gl.ONE, gl.CONSTANT_COLOR)
    // pass.surface.baseColor.attachConstant([0, 0, 0])
    // pass.surface.baseColorFactor.attachConstant([0, 0, 0])


    m = this.gltf.getElementByName(GltfTypes.MATERIAL, 'redglass') as unknown as Material
    pass = m.materialPass as StandardPass
    pass.glconfig
      .enableBlend()
      .blendColor(.8, 0, 0, 1.0)
      .blendFunc(gl.ONE, gl.CONSTANT_COLOR)
    pass.surface.baseColor.attachConstant([0, 0, 0])
    pass.surface.baseColorFactor.attachConstant([0, 0, 0])


    m = this.gltf.getElementByName(GltfTypes.MATERIAL, 'orangeglass') as unknown as Material
    pass = m.materialPass as StandardPass
    pass.glconfig
      .enableBlend()
      .blendColor(1, .5, 0, 1.0)
      .blendFunc(gl.ONE, gl.CONSTANT_COLOR)
    pass.surface.baseColor.attachConstant([0, 0, 0])
    pass.surface.baseColorFactor.attachConstant([0, 0, 0])



  }



  createCarPaint(): BaseMaterial {
    const pass = new ClearcoatSpecular()


    this.paintDiffuse = pass.surface.baseColor.attachUniform('color')
    
    this.paintSpecular = pass.surface.specular.attachUniform('spec')
    
    this.paintGloss = pass.surface.glossiness.attachUniform('ugloss')
    
    const clearcoat = pass.clearcoatReflectivity.attachUniform('cc')
    clearcoat.set(0.04)



/////////////////
/////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////
//////////////


    pass.glconfig
      .enableCullface(true)
      .enableDepthTest()

    const mat = new BaseMaterial(this.scene.gl, 'ccpaint')
    mat.addPass(pass, Passes.COLOR)

    this.passes.push(pass)

    return mat
  }





}
