













import UIBtn from "../UIBtn/UIBtn.vue";
import Vue from "vue";
import Component from "vue-class-component";
import Delay from "@/core/Delay";
import TrackingEv from "@/core/Tracking";
import ev_market from '@/store/modules/ev_market';

@Component({
  components: {
    UIBtn,
  },
})
export default class EVDeviceError extends Vue {
  async mounted() {
    TrackingEv.trackErrorModal();

    await Delay(500);
    this.$el.classList.add("show");
  }

  get linkChevrolet() {
    return this.$t("errors.fps_bail_cta")
  }

  get fpsTitle() {
    return this.$t("errors.fps_title") + `<br /><br />` + this.$t("errors.fps_text")
  }

  get rtl() {
    return ev_market.currentLocale == "ar-ae";
  }

  onClose() {
    TrackingEv.trackErrorModalBtn("close");
    this.$el.classList.remove("show");
  }

  onGotoChevrolet() {
    const url = this.$t("errors.fps_bail_href").toString();
    TrackingEv.trackErrorModalBtn(url);
    window.open(url, "_blank");
  }
}
