







import Vue from "vue";
import Component from "vue-class-component";
import GLApp from "@webgl/main";
import { CATCH_ASYNC } from "@/core/Async";
import EVModule from "@/webgl/EVModule";
import ev_state from "@/store/modules/ev_state";

@Component({
  components: {
    // EVHotspots
  },
})
export default class EVArView extends Vue {
  public loaded = false;
  public loadProgress = 0;

  private viewer: GLApp;

  mounted() {
    const canvas: HTMLCanvasElement = this.$refs.canvas as HTMLCanvasElement;
    this.viewer = new GLApp(canvas as HTMLCanvasElement, new EVModule());
    CATCH_ASYNC(this.viewer.load().then(this.onLoaded));

    ev_state.$watch(
      (s) => s.introDone,
      (introDone) => {
        if (introDone) {
          CATCH_ASYNC(this.viewer.loadAR().then(this.onLoadedAR));
        }
      }
    );

    if (process.env.VUE_APP_SKIP_8THWALL === "true") {
      // canvas.style.display = 'none'
    }
  }

  onLoaded() {
    if (process.env.VUE_APP_SKIP_8THWALL === "true") {
      this.loaded = true;
    }
  }

  onLoadedAR() {
    this.loaded = true;
  }
}
