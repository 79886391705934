










import ev_market from "@/store/modules/ev_market";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class RotateHint extends Vue {
  @Prop({ type: String, default: "ev" }) type!: string;

  mounted() {
    this.resize();
    window.addEventListener('resize', () => this.resize())
  }

  resize() {
    const mql = window.matchMedia("(orientation: portrait)").matches;

    if(mql) {
      document.documentElement.classList.remove("horizontal");
      document.body.classList.remove("horizontal");
    } else {
      document.documentElement.classList.add("horizontal");
      document.body.classList.add("horizontal");
    }
  }

  get rtl() {
    return ev_market.currentLocale == "ar-ae";
  }
}
