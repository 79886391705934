




















import { UspPanelData } from "@/store/models/UspPanelData";
import ev_state, { ARInitState } from "@/store/modules/ev_state";
import Vue from "vue";
import Component from "vue-class-component";
import EVArView from "../EVArView/EVArView.vue";
import EVColorPicker from "../EVColorPicker/EVColorPicker.vue";
// import EVHints, { HintType } from "../EVHints/EVHints.vue";
import EVHotspots from "../EVHotspots/EVHotspots.vue";
import EVInOutSwitch from "../EVInOutSwitch/EVInOutSwitch.vue";
import EVMenuPage from "../EVMenuPage/EVMenuPage.vue";
import EVNavBar from "../EVNavBar/EVNavBar.vue";
import EVUspPanel from "../EVUspPanel/EVUspPanel.vue";
import EVIntro from "../EVIntro/EVIntro.vue";
import EVChargingUI from "../EVChargingUI/EVChargingUI.vue";
import EVDeviceError from "../EVDeviceError/EVDeviceError.vue";
import LoadingPage from "../LoadingPage/LoadingPage.vue";
import ev_market from '@/store/modules/ev_market';
import Delay from '../../core/Delay';

@Component({
  components: {
    EVNavBar,
    EVArView,
    EVHotspots,
    EVInOutSwitch,
    EVUspPanel,
    EVColorPicker,
    EVIntro,
    EVChargingUI,
    EVDeviceError,
    LoadingPage,
    // EVHints,
    EVMenuPage,
  },
})
export default class EVArPage extends Vue {
  hideIntro: boolean = false;

  async mounted() {
    window.addEventListener('resize', () => this.resize());
  }

  resize() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  get showIntro(): boolean {
    return !this.hideIntro;
  }

  get showLoading(): boolean {
    return ev_state.uIExpShown;
    // ||
    // (ev_state.viewType === ViewType.OUTSIDE && !ev_state.exteriorLoaded)
  }

  get usp(): UspPanelData {
    return ev_state.currentUsp;
  }

  get rtl() {
    return ev_market.currentLocale == "ar-ae";
  }

  get showInOutSwitch() {
    return (
      ev_state.arInitState === ARInitState.READY ||
      ev_state.arInitState === ARInitState.TAP_TO_PLACE ||
      ev_state.arInitState === ARInitState.PLUG_INTRO
    );
  }

  get showHotspots() {
    return ev_state.arInitState === ARInitState.READY && ev_state.uIExpShown;
  }

  get showColorPicker() {
    return ev_state.arInitState === ARInitState.READY && ev_state.uIExpShown;
  }

  get showChargingUI() {
    return ev_state.arInitState === ARInitState.CHARGING;
  }

  get showMenu() {
    return ev_state.uIExpShown;
  }

  get isFPSWarning() {
    return ev_state.isFPSWarning;
  }

  introDone() {
    this.hideIntro = true;
  }
}
