











import { HotspotData } from "@/store/modules/ev_hotspots";
import ev_state from "@/store/modules/ev_state";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PlusSign from "!vue-svg-loader!@/assets/icons/plus.svg";
import Delay from "@/core/Delay";

@Component({
  components: {
    PlusSign,
  },
})
export default class EVHotspot extends Vue {
  @Prop({ type: Object, required: true }) hotspot!: HotspotData;
  @Prop({ type: Boolean, required: true, default: true }) show: boolean;

  get style(): string {
    return `transform: translate( calc(${this.hotspot.x *
      100}vw - 23px), calc(${this.hotspot.y * 100}vh - 23px) );`;
  }

  get btnStyle(): string {
    return `opacity: ${this.hotspot.opacity}; ${this.hotspot.opacity < 1 ? 'pointer-events:none;':''}`;
  }

  async onClick() {
    if (this.hotspot.opacity > 0.01) {
      ev_state.openUspId(this.hotspot.id);
      this.$el.classList.add("clicked");
      await Delay(100);
      this.$el.classList.add("clicked-next");
      await Delay(1500);
      this.$el.classList.remove("clicked-next");
      this.$el.classList.remove("clicked");
    }
  }
}
