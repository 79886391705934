import { HotspotData } from "@/store/modules/ev_hotspots";
import Node from "@/webgl/lib/nanogl-gltf/lib/elements/Node";
import { mat4Xaxis } from "@/webgl/math/mat4-axis";
import {  mat4, vec3, vec4 } from "gl-matrix";
import Camera from "nanogl-camera";
import { IProjector } from "./IProjector";

const M4A = mat4.create()
const V4A = vec4.create()
const V3A = vec3.create()

export default class ExteriorProjector implements IProjector{

  private nodes: Map<string, Node> = new Map()

  constructor( private rootNode : Node ){
    for (let i = 0; i < rootNode._children.length; i++) {
      const child = rootNode._children[i] as Node;
      //console.log(child.name);
      
      this.nodes.set( child.name, child )
    }
  }

  projectHotspots( list: HotspotData[], camera : Camera ): void {

    mat4Xaxis(V3A, this.rootNode._wmatrix)
    const scale = vec3.length(V3A)

    // let c = 0
    for (const h of list) {

      const n = this.nodes.get(h.id)
      
      V4A.set(n._wposition)
      V4A[3] = 1.0

      
      
      
      vec4.transformMat4(V4A, V4A, camera._viewProj )
      h.x = (V4A[0] / V4A[3]) * 0.5 + 0.5
      h.y = -(V4A[1] / V4A[3]) * 0.5 + 0.5
      
      // if( c=== 0){
      //   console.log(V4A[3] );
      // }
      // c++

      if( V4A[3] < 0 ||  V4A[3] > 10*scale ){
        h.opacity = 0
      } else {
        const coneAngle = n.scale[0]
        M4A.set( n._wmatrix )
        M4A[0] /= coneAngle
        M4A[1] /= coneAngle
        M4A[2] /= coneAngle
        mat4.invert( M4A, M4A )
        vec3.transformMat4( V3A, camera._wposition as vec3, M4A )
        vec3.normalize( V3A, V3A )
        
        const acos = Math.cos(coneAngle/2)
        
        h.opacity = (acos < -V3A[0])?1:0
      }
    }
  }

}