import { HexToVec } from "@/webgl/lib/color";
import { vec3 } from "gl-matrix";

const c = vec3.create()
HexToVec( 0x538eff, c)
vec3.scale( c, c, 2)


const glowTint = {
  value: c
}

/////////////
/////////////////////////////////////
////////////////////////////////////////////////////
//////////

export default glowTint