









import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

export enum BugerState {
  BURGER,
  CLOSE,
}

@Component
export default class EVNavBtn extends Vue {
  @Prop({ type: Boolean, default: false }) light!: boolean;

  @Prop({ type: String, required: true }) icon!: string;
}
