/*
 * register components globally
 */


import icons from '@/assets/icons'
import Vue     from 'vue'
import UIBtn from './UIBtn/UIBtn.vue'
import UIIcon from './UIIcon/UIIcon.vue'
import '@/mixins/Autodir'




icons.register();

Vue.component( 'icon', UIIcon )
Vue.component( 'btn', UIBtn )