import Scene from "@/webgl/Scene"
import GLArrayBuffer from "nanogl/arraybuffer"
import Program from "nanogl/program"

import line_VS from './line.vert'
import line_FS from './line.frag'
import { LocalConfig } from "nanogl-state"
import LazyTexture from "@/webgl/gl/LazyTexture"
import Paths from "@/core/Paths"
import Node from "nanogl-node"
import glowTint from "./glowtint"

/*
angle, side
*/
const FLOAT_PER_VERTEX = 2
const NUM_SEGS = 128


export type CircleConfig = {
  thickness: number
  radius: number
  height:number
}

export default class CircleLines {

  vbuffer : GLArrayBuffer
  line_prg: Program
  lineCfg: LocalConfig
  lineTex: LazyTexture
  node: Node
  speed = 30
  separation = 1

  

  constructor( private scene: Scene ){
    const gl = this.scene.gl;
    
    this.node = new Node()

    this.vbuffer = this._createCircleGeom()

    this.lineTex = new LazyTexture( Paths.resolve('assets/webgl/neon.png'), false, false)
    // this.lineTex = new LazyTexture( Paths.resolve('assets/webgl/checkerboard.png'), false, false)
    
    this.line_prg = this.scene.programs.create('lines'+Math.random(), line_VS(), line_FS())

    this.lineCfg = scene.glstate.config()
      .enableBlend()
      .blendFunc( gl.ONE, gl.ONE )
      .enableCullface(true)
      .depthMask(false)
      .enableDepthTest()

  }


  _createCircleGeom():GLArrayBuffer{

    const v_per_line = NUM_SEGS+1
    
    const vdata = new Float32Array( v_per_line*FLOAT_PER_VERTEX*2 );
    let i = 0
    
    for (let vidx = 0; vidx < v_per_line; vidx++) {
      
      vdata[i+0] = 
      vdata[i+2] = vidx/NUM_SEGS
      
      vdata[i+1] = 1
      vdata[i+3] = -1
      
      i+= FLOAT_PER_VERTEX*2
    }
    
    const gl = this.scene.gl;
    const buffer = new GLArrayBuffer( gl, vdata )
    buffer.attrib( 'aData', 2, gl.FLOAT)

    return buffer;
  }


  load() : Promise<void>{
    return this.lineTex.loadAndAllocate( this.scene.gl)
  }

  render( circles : CircleConfig[]  ){
    const cam = this.scene.camera
    const prg = this.line_prg


    const cycle = (this.scene.time / this.speed)%1.0 * Math.PI*2.0

    prg.use()
    this.vbuffer.attribPointer( prg )

    prg.uWorldMatrix(this.node._wmatrix);
    prg.uViewProjMatrix(cam._viewProj);
    prg.uCameraWorldPos(cam._wposition);
    prg.uNoiseParams(.25)
    prg.uTime(cycle)
    prg.uTex(this.lineTex.texture)
    prg.uTint( glowTint.value )
    
    this.lineCfg.apply()

    for (let i = 0; i < circles.length; i++) {
      const l = circles[i];
      prg.uParams( i*this.separation, l.thickness, l.radius, l.height )  
      this.vbuffer.drawTriangleStrip()
    }

  }


}