













import icons from '@/assets/icons';
import Vue, { ComponentOptions } from 'vue'
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class UIIcon extends Vue {

  @Prop({type:String, required:true}) name!: string

  @Prop({type:String, required:false}) color: string

  get colorStyle(): string{
    return (this.color!==undefined)?`color :${this.color}; `:''
  }

  get hasColor(): boolean{
    return this.color!==undefined
  }

  get icon(): ComponentOptions<Vue>{
    return icons(this.name);
  }
}

