
      module.exports = {
        functional: true,
        render(_h, _vm) {
          const { _c, _v, data, children = [] } = _vm;

          const {
            class: classNames,
            staticClass,
            style,
            staticStyle,
            attrs = {},
            ...rest
          } = data;

          return _c(
            'svg',
            {
              class: [classNames,staticClass],
              style: [style,staticStyle],
              attrs: Object.assign({"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}, attrs),
              ...rest,
            },
            children.concat([_c('path',{attrs:{"d":"M8 0C3.588 0 0 3.588 0 8s3.588 8 8 8 8-3.588 8-8-3.588-8-8-8zm0 15.007c-1.078 0-2.038-1.196-2.644-2.981.825.151 1.701.235 2.644.235.943 0 1.82-.084 2.644-.235-.606 1.785-1.566 2.98-2.644 2.98zm0-3.756a13.36 13.36 0 01-2.93-.32 13.362 13.362 0 01-.32-2.93c0-1.044.118-2.038.32-2.93.893-.202 1.886-.32 2.93-.32 1.044 0 2.038.118 2.93.32.202.892.32 1.886.32 2.93 0 1.044-.118 2.038-.32 2.93a13.36 13.36 0 01-2.93.32zm-4.025-.607C2.19 10.038.994 9.078.994 8s1.196-2.038 2.98-2.644A14.593 14.593 0 003.74 8c0 .944.101 1.82.236 2.644zM8 .994c1.078 0 2.038 1.195 2.644 2.98A14.592 14.592 0 008 3.74c-.943 0-1.82.084-2.644.235C5.962 2.19 6.922.994 8 .994zm4.025 4.362c1.785.59 2.98 1.566 2.98 2.644s-1.195 2.038-2.98 2.644c.151-.825.236-1.701.236-2.644 0-.944-.101-1.82-.236-2.644zm2.51.168c-.725-.539-1.667-.977-2.78-1.296-.303-1.112-.757-2.055-1.296-2.78a7.044 7.044 0 014.076 4.076zm-9.01-4.059c-.54.724-.978 1.667-1.297 2.779-1.112.303-2.055.758-2.78 1.297a7.044 7.044 0 014.076-4.076zm-4.06 9.01c.724.54 1.667.978 2.779 1.297.303 1.112.758 2.055 1.297 2.78a7.044 7.044 0 01-4.076-4.076zm9.01 4.06c.54-.725.977-1.667 1.297-2.78 1.112-.302 2.055-.757 2.78-1.296a7.044 7.044 0 01-4.076 4.076z","fill":"#fff"}}),_c('path',{attrs:{"d":"M8 5.928A2.07 2.07 0 005.928 8 2.07 2.07 0 008 10.072 2.07 2.07 0 0010.072 8 2.07 2.07 0 008 5.928zm0 3.15c-.59 0-1.078-.489-1.078-1.078 0-.59.489-1.078 1.078-1.078.59 0 1.078.488 1.078 1.078 0 .59-.489 1.078-1.078 1.078z","fill":"#fff"}})])
          )
        }
      }
    