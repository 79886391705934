
import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'
import ebn_state from './ebn_state'

@Module
export class AudioState extends VuexModule {
  
  public mainVolume = 1

  get isMuted():boolean {
    return this.mainVolume < 0.001
  }


  @Action
  mute() {
    this._SET_VOLUME(0)
  }

  @Action
  unmute(force:boolean = false) {
    this._SET_VOLUME(ebn_state.volumeMeterEnabled || force ? 0.5 : 1)
  }

  @Mutation
  private _SET_VOLUME(v:number) {
    console.log('set volume', v, ebn_state.volumeMeterEnabled)
    this.mainVolume = v
  }
}

const audio_state = new AudioState({ store, name: 'audio_state' })

export default audio_state
